import { Localizations } from '@/app/Utilities/localization';
import { IUserRepository } from '@/app/core/IRepositories/IUserRepository';
import { IUsersRepository } from '@/app/core/IRepositories/IUsersRepository';
import { MentionMember } from '@/app/core/Models/Mention';
import { SuccessResponse } from '@/app/Data/DTO/successResponse';
import { AppState } from '@/app/State/AppState';
import { InviteUserAction, DeleteInvitationAction, FetchInvitationsListAction, FetchTeamMembersAction, FetchUsersListAction, UpdateUserAction } from '@/app/State/users/action';
import { selectCreatingUser, selectInvitations, selectTeamMemberOnlineRef } from '@/app/State/users/selector';
import { SnackbarService } from '@/app/Utilities/snackbar/snackbar.service';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { catchError, combineLatest, distinctUntilChanged, map, Observable, shareReplay, tap } from 'rxjs';
import { InviteUserDto, EditUserDto, EditUserPermissionDto } from './../../Data/DTO/CreateUserDto';
import { UserFormComponent } from '../Users/user-form/user-form.component';
import { MatDialog } from '@angular/material/dialog';
import { selectInboxes } from '@/app/State/Inbox/inbox.selector';
import { User } from '@/app/core/Models/User';

@Injectable({
  providedIn: 'root',
})
export class UsersViewModel {
  constructor(
    private store: Store<AppState>,
    private usersRepository: IUsersRepository,
    private userRepository: IUserRepository,
    private snackBarService: SnackbarService,
    private matDialog: MatDialog
  ) { }

  readonly onlineMembers$ = this.store.select(selectTeamMemberOnlineRef);
  readonly creatingUser$ = this.store.select(selectCreatingUser);
  tenantUsersMap = new Map<string, User>() // this will be used for local Joining to easy access instead of array.find that cost a lot
  inboxes$ = this.store.select(selectInboxes)
  usersList$ = this.usersRepository.getLocalUsersList().pipe(
    shareReplay({ refCount: true, bufferSize: 1 }),
    tap(users => this.tenantUsersMap = new Map(users.map(obj => [obj.user_id + '', obj])))
  );
  invitationsList$ = this.store.select(selectInvitations).pipe(
    shareReplay({ refCount: true, bufferSize: 1 })
  );

  teamMembers$ = combineLatest([
    this.usersRepository.getLocalTeamMembers(),
    this.onlineMembers$,
  ]).pipe(
    distinctUntilChanged(),
    map(([members, onlineRef]) =>
      members.map((member) => {
        const ref = onlineRef.find((item) => item.user_id == member.user_id);
        return { ...member, active: ref ? ref.isConnected : member?.active };
      })
    ),
    shareReplay({ refCount: true, bufferSize: 1 })
  );

  mentions$: Observable<MentionMember[]> = this.teamMembers$.pipe(
    map(team => team.map(member => ({
      name: `${member?.given_name} ${member?.family_name}`.replace(/(\s)+/gi, '_'),
      id: member.user_id,
      img: member.avatar,
      active: member.active
    }))),
    // map(team => [...team,
    //   { name: 'All', id: 'all', img: 'assets/images/loud-speaker.png', active: false },
    //   { name: 'here', id: 'here', img: 'assets/images/loud-speaker.png', active: false}
    // ])
  )

  fetchUsersList(): void {
    this.store.dispatch(FetchUsersListAction());
  }

  fetchInvitationsList(): void {
    this.store.dispatch(FetchInvitationsListAction());
  }

  fetchTeamMembers(): void {
    this.store.dispatch(FetchTeamMembersAction());
  }

  openUserForm(user?: User): void {
    this.matDialog.open(UserFormComponent, {
      backdropClass: 'templateBackDrop',
      panelClass: 'custom-dialog-container',
      maxHeight: 'min(80vh, 50rem)',
      width: '500px',
      data: {
        user,
      },
    });
  }

  createUser(request: InviteUserDto) {
    this.store.dispatch(InviteUserAction({ payload: request }))
  }

  updateUser(request: EditUserDto, userId: number) {
    this.store.dispatch(UpdateUserAction({ payload: { request, userId } }))
  }

  deleteUser(userId: number): Observable<SuccessResponse> {
    return this.userRepository.deleteUser({ user_id: userId }).pipe(
      tap(() => {
        this.userRepository.deleteLocalUser(userId as any);
        this.snackBarService.openAlert({
          message: Localizations.alert_dialog.user_deleted,
          type: 'success',
        });
        this.fetchUsersList();
      }),
      catchError((err) => {
        this.snackBarService.openAlert({
          message: Localizations.alert_dialog.user_not_deleted,
          type: 'failure',
        });
        throw err;
      })
    );
  }

  deleteInvitation(invitation_id: number) {
    this.store.dispatch(DeleteInvitationAction({ payload: { invitation_id } }))
  }

  reinviteUser(invitation_id: number): Observable<SuccessResponse> {
    return this.userRepository
      .reinviteUser({ invitation_id })
      .pipe(
        tap(() => {
          this.snackBarService.openAlert({
            message: Localizations.alert_dialog.invitation_email_sent,
            type: 'success',
          });
        }),
        catchError((err) => {
          this.snackBarService.openAlert({
            message: Localizations.alert_dialog.invitation_email_not_sent,
            type: 'failure',
          });
          throw err;
        })
      );
  }

  editUserAIPermission(request: EditUserPermissionDto) {
    return this.userRepository.editUserAIPermission(request);
  }

}
