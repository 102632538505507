<div class="p-2" class="flex flex-col justify-between gap-5">
  <div
        *ngIf="data?.title"
        class="flex flex-row">
        <h2 
            class="text-xl font-semibold text-dark">
            {{data?.title}}
        </h2>
  </div>
  <div>
    <div class="flex flex-col"
        *ngIf="data?.inputForm !== undefined">
        <app-input-wrapper
            class="flex-1"
            [label]="data?.inputForm!.label">
            <input
                input
                type="text"
                [ngModelOptions]="{standalone: true}"
                [(ngModel)]="data?.inputForm!.value"
                cdkFocusInitial
                autocomplete="off"/>
        </app-input-wrapper>
    </div>
  </div>
  <div class="text-semibold text-base mt-4">{{ data.message | translate : data.translateParams }}</div>
  <div mat-dialog-actions class="flex flex-row w-full justify-between gap-10 items-center">
    <app-button color="light" (click)="cancel()">
      {{ (data.cancel ??  localization.labels.cancel) | translate }}
    </app-button>

    <app-button [color]="data.warning ? 'warning' : 'primary'" (click)="close()">
      {{ (data.confirm ??  localization.labels.confirm) | translate }}
    </app-button>
  </div>
</div>
