import { CountryCode, format, isValidNumberForRegion, parsePhoneNumber } from "libphonenumber-js";
import { FileModel, FileType } from "../core/Models/file";
import { DomSanitizer } from "@angular/platform-browser";

// (?![^<]*>) avoid matching inside html tags
// (?![^>]*<\/) avoid matching between html tags
const html_tags = /(?=>[^>]*<\/)/gm
const pin_regex = /(?![^<]*>)(?![^>]*<\/)\b(\d{4,6})\b/gm
const us_phone_regex = /(?![^>]*<\/)((\B\([0-9]{3}\)|\b[0-9]{3})\s?[0-9]{3}-[0-9]{4}\b)/gm
const http_url_regex = /(?![^<]*>)(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%_\+.~#?&\/\/=]*))/ig
const break_line_regex = /(?:\r\n|\r|\n)/g


export const extractCountryCode = (phone: string, countryCode?: CountryCode): CountryCode | undefined => {
    return parsePhoneNumber(phone, countryCode)?.country
}

export const checkPhoneValidity = (phone: string, countryCode: CountryCode): boolean => {
    try {
        const code = extractCountryCode(phone, countryCode) ?? countryCode;
        return isValidNumberForRegion(phone, code)
    } catch { return false }
}

export const getFormattedPhoneNumber = (phone: string, countryCode: CountryCode): string => {
    const code = extractCountryCode(phone, countryCode) ?? countryCode;
    return format(phone, code, 'E.164')
}

export const formatMessageContent = (text: string): string => {

    return html_tags.test(text)
        ? text
        : text
            .replace(http_url_regex, '<a rel="nofollow" target="_blank" class="text-primary" href="$1">$1</a>')
            .replace(us_phone_regex, '<span class="phone-number text-primary">$1</span>')
            .replace(pin_regex, '<span class="pin-code text-primary">$1</span>')
            .replace(break_line_regex, '<br>')
}

export const DtoToFormData = (data: any): FormData => {
    const formData = new FormData();
    for (const key in data) {
        if (data.hasOwnProperty(key)) {
            const value: Array<any> = Array.isArray(data[key]) ? data[key] : [data[key]];
            for (const item of value) {
                item instanceof File || item instanceof Blob
                    ? formData.append(key, item)
                    : typeof item === 'object'
                        ? formData.append(key, JSON.stringify(item))
                        : formData.append(key, item);

            }
        }
    }
    return formData;
}

/**
 *
 * @param queryString example name=john&email=johndoe@mail.com
 * @returns
 */
export const extractQueryParams = (queryString: string): Record<string, string> => {
    const queryParams: Record<string, string> = {};
    const pairs = queryString.split('&');

    for (const pair of pairs) {
        const [key, value] = pair.split('=');
        queryParams[key] = decodeURIComponent(value);
    }

    return queryParams;
}

export const processFiles = (files: File[], domSanitizer?: DomSanitizer) => {
    const promises = files.map(file =>
        new Promise<FileModel>((res, rej) => {
            if (domSanitizer && file.type.includes('video')) {
                try {
                    const model: FileModel = {
                        file,
                        type: 'video',
                        name: file.name,
                        url: domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file)) as string,
                    }
                    res(model)
                } catch (err) {
                    rej()
                }
            }
            if (domSanitizer && file.type.includes('audio')) {
                try {
                    const model: FileModel = {
                        file,
                        type: 'audio' as any,
                        name: file.name,
                        url: domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file)) as string,
                    }
                    res(model)
                } catch (err) {
                    rej()
                }
            } else {
                const reader = new FileReader();
                reader.onload = () => {
                    res({
                        file,
                        name: file.name,
                        url: reader.result as string,
                        type: file.type.includes('image') ? 'image' : file.type.includes('video') ? 'video' : file.type.includes('audio') ? 'audio' as any : 'file',
                    })
                };
                reader.readAsDataURL(file);
            }
        })
    );
    return Promise.all(promises)
}

export const resizeImage = async (data: { MAX_WIDTH: number, MAX_HEIGHT: number, file: File, MIME_TYPE?: string }): Promise<File> => {
    // ex: for avatars call this fun with 150px MAX_WIDTH & MAX_HEIGHT
    if (data.file.type.includes('gif')) return data.file;
    const calculateSize = (img: HTMLImageElement, maxWidth: number, maxHeight: number) => {
        let width = img.width;
        let height = img.height;
        if (width > height) {
            if (width > maxWidth) {
                height = Math.round((height * maxWidth) / width);
                width = maxWidth;
            }
        } else {
            if (height > maxHeight) {
                width = Math.round((width * maxHeight) / height);
                height = maxHeight;
            }
        }
        return [width, height];
    }
    const { MAX_WIDTH, MAX_HEIGHT, MIME_TYPE, file } = data
    const file_type = file.type
    const blobURL = URL.createObjectURL(file);
    const img = new Image();
    img.src = blobURL;
    return new Promise<File>((res, rej) => {
        img.onerror = () => {
            URL.revokeObjectURL(img.src);
            rej("Cannot load image")
        };
        img.onload = (e) => {
            URL.revokeObjectURL(img.src);
            const [newWidth, newHeight] = calculateSize(img, MAX_WIDTH, MAX_HEIGHT);
            const canvas = document.createElement("canvas");
            canvas.width = newWidth;
            canvas.height = newHeight;
            const ctx = canvas.getContext("2d");
            ctx?.drawImage(img, 0, 0, newWidth, newHeight);
            canvas.toBlob(
                (blob) => {
                    res(new File([blob as Blob], file.name ?? new Date().getTime().toString(), { type: file_type }))
                },
                MIME_TYPE || ['image/jpeg', 'image/png'].includes(file.type) ? file_type : 'image/jpeg', 0.9
            );
        };
    })
}

export const fileTypeFromName = (name?: string): FileType => {
    return !name ? 'file'
        : name.startsWith('image') || name.match(/\.(jpeg|jpg|gif|png|webp)$/) != null ? 'image'
            : name.includes('pdf') || name.match(/\.(pdf)$/) != null ? 'pdf'
                : name.startsWith('audio') || name.match(/\.(mp3|webm|wav|ogg|aac)$/) != null ? 'audio' as any
                    : name.startsWith('video') || name.match(/\.(mp4|flv|avi|mkv|mov)$/) != null ? 'video'
                        : 'file'
}

export const dynamicKeySort = (sortKey: string, direction: 'asc' | 'desc' = 'asc'): any => {
    const sortIndexOrder = direction === 'asc' ? 1 : -1;
    return (inner: any, outer: any) => {
        let innerValue = inner[sortKey];
        let outerValue = outer[sortKey];
        if (typeof innerValue === "string") {
            innerValue = ("" + innerValue).toLowerCase();
            outerValue = ("" + outerValue).toLowerCase();
        }
        const num = (innerValue < outerValue) ? -1 : (innerValue > outerValue) ? 1 : 0;
        return num * sortIndexOrder;
    }
}

export const bufferToWave = (audiobuffer: AudioBuffer, len: number) => {
    let numOfChan = audiobuffer.numberOfChannels, // let's say 2 channels
        length = len * numOfChan * 2 + 44,        // * 2: Each sample is 2 bytes (16 bits) | 44 => header each 32 is 4bytes and 16 is two bytes => 9 * 4 + 4 * 2 = 44
        buffer = new ArrayBuffer(length),
        view = new DataView(buffer),
        channels: Float32Array[] = [],
        i, sample,
        offset = 0,
        pos = 0;

    // write WAVE header
    setUint32(0x46464952);                         // "RIFF"
    setUint32(length - 8);                         // file length - 8
    setUint32(0x45564157);                         // "WAVE"

    setUint32(0x20746d66);                         // "fmt " chunk
    setUint32(16);                                 // length = 16
    setUint16(1);                                  // PCM (uncompressed)
    setUint16(numOfChan);
    setUint32(audiobuffer.sampleRate);
    setUint32(audiobuffer.sampleRate * 2 * numOfChan); // avg. bytes/sec
    setUint16(numOfChan * 2);                      // block-align
    setUint16(16);                                 // 16-bit (hardcoded in this demo)

    setUint32(0x61746164);
    setUint32(length - pos - 8);                   // chunk length

    // write interleaved data
    for (i = 0; i < audiobuffer.numberOfChannels; i++)
        channels.push(audiobuffer.getChannelData(i));

    while (pos < length) {
        for (i = 0; i < numOfChan; i++) {             // interleave channels
            sample = Math.max(-1, Math.min(1, channels[i][offset])); // clamp
            sample = (0.5 + sample < 0 ? sample * 32768 : sample * 32767) | 0; // scale to 16-bit signed int
            view.setInt16(pos, sample, true);          // write 16-bit sample
            pos += 2;
        }
        offset++                                     // next source sample
    }

    // create Blob
    return new Blob([buffer], { type: "audio/wav" });

    function setUint16(data: number) {
        view.setUint16(pos, data, true);
        pos += 2;
    }

    function setUint32(data: number) {
        view.setUint32(pos, data, true);
        pos += 4;
    }
}

export const formatMentionContent = (value: string): string => {
    let addBreak = /(.*)(\r\n|\r|\n)/.test(value);
    value = value.replace(/<[^(>|(\r\n|\r|\n)]*>/g, '').replace(/</g, '&lt;').replace(/</g, '&lt;');
    value = value.replace(/(\r\n|\r|\n)/g, '</br>');
    value = value.replace(/(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%_\+.~#?&\/\/=]*))/ig, '<span class="link">$1</span>');
    value = value.replace(/(#[A-Za-z0-9\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff_&]*)/ig, '<span class="hashtag">$1</span>');
    value = value.replace(/(^|\s)@([a-z0-9][a-z0-9_&]*)/ig, (match, p1, p2) => {
        const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (emailRegex.test(`${p2}`)) {
            return match;
        }
        return `${p1}<span class="mention">@${p2}</span>`;
    });
    value = value.replace(/(\[contact: ([\w\s]+)\])/ig, '<span class="contact-field">$1</span>');
    addBreak && (value = value + '<br>');
    return value;
}

export const getCompanyTypes = (): Array<{ name: string, value: string }> => {
    return [
        {
            name: 'Partner',
            value: 'Partner'
        },
        {
            name: 'Prospect',
            value: 'Prospect'
        },

        {
            name: 'Reseller',
            value: 'Reseller'
        },
        {
            name: 'Vendor',
            value: 'Vendor'
        },
        {
            name: 'Customer/Client',
            value: 'Customer/Client'
        },
        {
            name: 'Other',
            value: 'Other'
        },
    ];
}

export const getIndustryTypes = (): Array<{ name: string, value: string }> => {
    return [
        {
            name: 'Automobiles & Components',
            value: 'Automobiles & Components'
        },
        {
            name: 'Banks',
            value: 'Banks'
        },
        {
            name: 'Capital Goods',
            value: 'Capital Goods'
        },
        {
            name: 'Commercial & Professional Services',
            value: 'Commercial & Professional Services'
        },
        {
            name: 'Consumer Discretionary Distribution & Retail',
            value: 'Consumer Discretionary Distribution & Retail'
        },
        {
            name: 'Consumer Durables & Apparel',
            value: 'Consumer Durables & Apparel'
        },
        {
            name: 'Consumer Services',
            value: 'Consumer Services'
        },
        {
            name: 'Consumer Staples Distribution & Retail',
            value: 'Consumer Staples Distribution & Retail'
        },
        {
            name: 'Energy',
            value: 'Energy'
        },
        {
            name: 'Equity Real Estate Investment Trusts',
            value: 'Equity Real Estate Investment Trusts'
        },
        {
            name: 'Financial Services',
            value: 'Financial Services'
        },
        {
            name: 'Food, Beverage & Tobacco',
            value: 'Food, Beverage & Tobacco'
        },
        {
            name: 'Health Care Equipment & Services',
            value: 'Health Care Equipment & Services'
        },
        {
            name: 'Household & Personal Products',
            value: 'Household & Personal Products'
        },
        {
            name: 'Insurance',
            value: 'Insurance'
        },
        {
            name: 'Materials',
            value: 'Materials'
        },
        {
            name: 'Media & Entertainment',
            value: 'Media & Entertainment'
        },
        {
            name: 'Partner',
            value: 'Partner'
        },
        {
            name: 'Pharmaceuticals, Biotechnology & Life Sciences',
            value: 'Pharmaceuticals, Biotechnology & Life Sciences'
        },
        {
            name: 'Prospect',
            value: 'Prospect'
        },
        {
            name: 'Real Estate Management & Development',
            value: 'Real Estate Management & Development'
        },
        {
            name: 'Reseller',
            value: 'Reseller'
        },
        {
            name: 'Semiconductors & Semiconductor Equipment',
            value: 'Semiconductors & Semiconductor Equipment'
        },
        {
            name: 'Software & Services',
            value: 'Software & Services'
        },
        {
            name: 'Technology Hardware & Equipment',
            value: 'Technology Hardware & Equipment'
        },
        {
            name: 'Telecommunication Services',
            value: 'Telecommunication Services'
        },
        {
            name: 'Transportation',
            value: 'Transportation'
        },
        {
            name: 'Utilities',
            value: 'Utilities'
        },
        {
            name: 'Vendor',
            value: 'Vendor'
        },
        {
            name: 'Other',
            value: 'Other'
        }
    ];

}

export const removeItemInArray: Function = (array: Array<any>, itemToRemove: any): Array<any> => {
    const index: number = array.indexOf(itemToRemove);
    if (index > -1) array.splice(index, 1);
    return array;
}

export const removeItemInArrayObject: Function = (array: Array<any>, objectKey: string, objectValue: string): Array<any> => {
    const index = array.findIndex((key) => key[objectKey] === objectValue);
    array.splice(index, 1);
    return array;
}

export const paginateArray: Function = (items: Array<any>, pageSize: number, pageNumber: number): Array<any> => {
    --pageNumber; // because pages logically start with 1, but technically with 0
    return items.slice(pageNumber * pageSize, (pageNumber + 1) * pageSize);
};

export const getBodyAttachment: Function = (attachments: Array<{ filename: string, type: string, url: string, file?: File }>): FormData | undefined => {
    const form = new FormData();
    attachments
        .map((attachment) => attachment.file)
        .filter((file): file is File => !!file)
        .forEach((file) => {
            form.append(file.type.startsWith('image/') ? 'image' : file.type.startsWith('video/') ? 'video' : 'file', file, file.name || '');
        });
    let counter = 0;
    form.forEach((item) => { counter++; });
    return counter ? form : undefined;
};

export const arrayIndexChanged: Function = (arr1: any[], arr2: any[], key: string): boolean => {
    const idToIndex = new Map();
    arr1.forEach((obj, index) => { idToIndex.set(obj[key], index); });
    let changed = false;
    for (let i = 0; i < arr2.length; i++) {
        const obj = arr2[i];
        const expectedIndex = idToIndex.get(obj[key]);
        if (expectedIndex !== i) {
            changed = true;
        }
    }
    return changed;
}

export const copyClipboard: Function = (content: any): void => {
    navigator.clipboard.writeText(content);
}

export const searchURLParams: Function = (url: string, toSearch: string): string => {
    let tempUrl = new URL(url);
    let result = tempUrl.searchParams.get(toSearch);
    return result as string;
};

export const disableDOM: Function = (): void => {
    const body: any = document.getElementsByTagName("body");
    body[0].style = "pointer-events: none;"
}

export const enableDOM: Function = (): void => {
    const body: any = document.getElementsByTagName("body");
    body[0].style = "pointer-events: visible;"
}

export const convertBase64ToBlob: Function = (base64Image: string): Blob => {
    const parts = base64Image.split(';base64,');
    const imageType = parts[0].split(':')[1];
    const decodedData = window.atob(parts[1]);
    const uInt8Array = new Uint8Array(decodedData.length);
    for (let i = 0; i < decodedData.length; ++i) {
        uInt8Array[i] = decodedData.charCodeAt(i);
    }
    return new Blob([uInt8Array], { type: imageType });
}

export const uniqBy = (arr: any[], key: string) => {
    return arr.reduce((acc, curr) => {
        const keyValue = curr[key];
        if (!acc.some((item: { [x: string]: any; }) => item[key] === keyValue)) {
            acc.push(curr);
        }
        return acc;
    }, []);
};


export const addPrefixURL: Function = (url: string): string => {
    const prefixes = ['http://', 'https://', 'www://'];
    if (!prefixes.some(prefix => url.startsWith(prefix))) {
        return 'https://' + url;
    }
    return url;
}


export const getUniqueObjectsById: Function = (items: any[], key: string): any[] => {
    const uniqueIds: Set<any> = new Set();
    return items.filter(item => {
        if (!uniqueIds.has(item[key])) {
            uniqueIds.add(item[key]);
            return true;
        }
        return false;
    });
}

export const formatBytes: Function = (bytes: number, decimals: number): any => {
    if (bytes === 0) {
        return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}


export const validateFilesType: Function = (files: any[], file_types: string[]): any[] => {
    const stashFiles = [];
    for (const file of files) {
        const allowed = file_types.includes(file.type);
        allowed && stashFiles.push(file);
    }
    return stashFiles;
}

export const uniqueBy: Function = <T>(array: T[], key: keyof T): T[] => {
    const uniqueItems: T[] = [];
    const seenValues = new Set<T[keyof T]>();
    for (const item of array) {
        const value = item[key];
        if (!seenValues.has(value)) {
            uniqueItems.push(item);
            seenValues.add(value);
        }
    }
    return uniqueItems;
}

export const getTimeZoneLocationRaw: Function = (): Array<any> => {
    return [
        "Africa/Abidjan",
        "Africa/Accra",
        "Africa/Addis_Ababa",
        "Africa/Algiers",
        "Africa/Asmara",
        "Africa/Asmera",
        "Africa/Bamako",
        "Africa/Bangui",
        "Africa/Banjul",
        "Africa/Bissau",
        "Africa/Blantyre",
        "Africa/Brazzaville",
        "Africa/Bujumbura",
        "Africa/Cairo",
        "Africa/Casablanca",
        "Africa/Ceuta",
        "Africa/Conakry",
        "Africa/Dakar",
        "Africa/Dar_es_Salaam",
        "Africa/Djibouti",
        "Africa/Douala",
        "Africa/El_Aaiun",
        "Africa/Freetown",
        "Africa/Gaborone",
        "Africa/Harare",
        "Africa/Johannesburg",
        "Africa/Juba",
        "Africa/Kampala",
        "Africa/Khartoum",
        "Africa/Kigali",
        "Africa/Kinshasa",
        "Africa/Lagos",
        "Africa/Libreville",
        "Africa/Lome",
        "Africa/Luanda",
        "Africa/Lubumbashi",
        "Africa/Lusaka",
        "Africa/Malabo",
        "Africa/Maputo",
        "Africa/Maseru",
        "Africa/Mbabane",
        "Africa/Mogadishu",
        "Africa/Monrovia",
        "Africa/Nairobi",
        "Africa/Ndjamena",
        "Africa/Niamey",
        "Africa/Nouakchott",
        "Africa/Ouagadougou",
        "Africa/Porto-Novo",
        "Africa/Sao_Tome",
        "Africa/Timbuktu",
        "Africa/Tripoli",
        "Africa/Tunis",
        "Africa/Windhoek",
        "America/Adak",
        "America/Anchorage",
        "America/Anguilla",
        "America/Antigua",
        "America/Araguaina",
        "America/Argentina/Buenos_Aires",
        "America/Argentina/Catamarca",
        "America/Argentina/ComodRivadavia",
        "America/Argentina/Cordoba",
        "America/Argentina/Jujuy",
        "America/Argentina/La_Rioja",
        "America/Argentina/Mendoza",
        "America/Argentina/Rio_Gallegos",
        "America/Argentina/Salta",
        "America/Argentina/San_Juan",
        "America/Argentina/San_Luis",
        "America/Argentina/Tucuman",
        "America/Argentina/Ushuaia",
        "America/Aruba",
        "America/Asuncion",
        "America/Atikokan",
        "America/Atka",
        "America/Bahia",
        "America/Bahia_Banderas",
        "America/Barbados",
        "America/Belem",
        "America/Belize",
        "America/Blanc-Sablon",
        "America/Boa_Vista",
        "America/Bogota",
        "America/Boise",
        "America/Buenos_Aires",
        "America/Cambridge_Bay",
        "America/Campo_Grande",
        "America/Cancun",
        "America/Caracas",
        "America/Catamarca",
        "America/Cayenne",
        "America/Cayman",
        "America/Chicago",
        "America/Chihuahua",
        "America/Coral_Harbour",
        "America/Cordoba",
        "America/Costa_Rica",
        "America/Creston",
        "America/Cuiaba",
        "America/Curacao",
        "America/Danmarkshavn",
        "America/Dawson",
        "America/Dawson_Creek",
        "America/Denver",
        "America/Detroit",
        "America/Dominica",
        "America/Edmonton",
        "America/Eirunepe",
        "America/El_Salvador",
        "America/Ensenada",
        "America/Fort_Nelson",
        "America/Fort_Wayne",
        "America/Fortaleza",
        "America/Glace_Bay",
        "America/Godthab",
        "America/Goose_Bay",
        "America/Grand_Turk",
        "America/Grenada",
        "America/Guadeloupe",
        "America/Guatemala",
        "America/Guayaquil",
        "America/Guyana",
        "America/Halifax",
        "America/Havana",
        "America/Hermosillo",
        "America/Indiana/Indianapolis",
        "America/Indiana/Knox",
        "America/Indiana/Marengo",
        "America/Indiana/Petersburg",
        "America/Indiana/Tell_City",
        "America/Indiana/Vevay",
        "America/Indiana/Vincennes",
        "America/Indiana/Winamac",
        "America/Indianapolis",
        "America/Inuvik",
        "America/Iqaluit",
        "America/Jamaica",
        "America/Jujuy",
        "America/Juneau",
        "America/Kentucky/Louisville",
        "America/Kentucky/Monticello",
        "America/Knox_IN",
        "America/Kralendijk",
        "America/La_Paz",
        "America/Lima",
        "America/Los_Angeles",
        "America/Louisville",
        "America/Lower_Princes",
        "America/Maceio",
        "America/Managua",
        "America/Manaus",
        "America/Marigot",
        "America/Martinique",
        "America/Matamoros",
        "America/Mazatlan",
        "America/Mendoza",
        "America/Menominee",
        "America/Merida",
        "America/Metlakatla",
        "America/Mexico_City",
        "America/Miquelon",
        "America/Moncton",
        "America/Monterrey",
        "America/Montevideo",
        "America/Montreal",
        "America/Montserrat",
        "America/Nassau",
        "America/New_York",
        "America/Nipigon",
        "America/Nome",
        "America/Noronha",
        "America/North_Dakota/Beulah",
        "America/North_Dakota/Center",
        "America/North_Dakota/New_Salem",
        "America/Ojinaga",
        "America/Panama",
        "America/Pangnirtung",
        "America/Paramaribo",
        "America/Phoenix",
        "America/Port-au-Prince",
        "America/Port_of_Spain",
        "America/Porto_Acre",
        "America/Porto_Velho",
        "America/Puerto_Rico",
        "America/Punta_Arenas",
        "America/Rainy_River",
        "America/Rankin_Inlet",
        "America/Recife",
        "America/Regina",
        "America/Resolute",
        "America/Rio_Branco",
        "America/Rosario",
        "America/Santa_Isabel",
        "America/Santarem",
        "America/Santiago",
        "America/Santo_Domingo",
        "America/Sao_Paulo",
        "America/Scoresbysund",
        "America/Shiprock",
        "America/Sitka",
        "America/St_Barthelemy",
        "America/St_Johns",
        "America/St_Kitts",
        "America/St_Lucia",
        "America/St_Thomas",
        "America/St_Vincent",
        "America/Swift_Current",
        "America/Tegucigalpa",
        "America/Thule",
        "America/Thunder_Bay",
        "America/Tijuana",
        "America/Toronto",
        "America/Tortola",
        "America/Vancouver",
        "America/Virgin",
        "America/Whitehorse",
        "America/Winnipeg",
        "America/Yakutat",
        "America/Yellowknife",
        "Antarctica/Casey",
        "Antarctica/Davis",
        "Antarctica/DumontDUrville",
        "Antarctica/Macquarie",
        "Antarctica/Mawson",
        "Antarctica/McMurdo",
        "Antarctica/Palmer",
        "Antarctica/Rothera",
        "Antarctica/South_Pole",
        "Antarctica/Syowa",
        "Antarctica/Troll",
        "Antarctica/Vostok",
        "Arctic/Longyearbyen",
        "Asia/Aden",
        "Asia/Almaty",
        "Asia/Amman",
        "Asia/Anadyr",
        "Asia/Aqtau",
        "Asia/Aqtobe",
        "Asia/Ashgabat",
        "Asia/Ashkhabad",
        "Asia/Atyrau",
        "Asia/Baghdad",
        "Asia/Bahrain",
        "Asia/Baku",
        "Asia/Bangkok",
        "Asia/Barnaul",
        "Asia/Beirut",
        "Asia/Bishkek",
        "Asia/Brunei",
        "Asia/Calcutta",
        "Asia/Chita",
        "Asia/Choibalsan",
        "Asia/Chongqing",
        "Asia/Chungking",
        "Asia/Colombo",
        "Asia/Dacca",
        "Asia/Damascus",
        "Asia/Dhaka",
        "Asia/Dili",
        "Asia/Dubai",
        "Asia/Dushanbe",
        "Asia/Famagusta",
        "Asia/Gaza",
        "Asia/Harbin",
        "Asia/Hebron",
        "Asia/Ho_Chi_Minh",
        "Asia/Hong_Kong",
        "Asia/Hovd",
        "Asia/Irkutsk",
        "Asia/Istanbul",
        "Asia/Jakarta",
        "Asia/Jayapura",
        "Asia/Jerusalem",
        "Asia/Kabul",
        "Asia/Kamchatka",
        "Asia/Karachi",
        "Asia/Kashgar",
        "Asia/Kathmandu",
        "Asia/Katmandu",
        "Asia/Khandyga",
        "Asia/Kolkata",
        "Asia/Krasnoyarsk",
        "Asia/Kuala_Lumpur",
        "Asia/Kuching",
        "Asia/Kuwait",
        "Asia/Macao",
        "Asia/Macau",
        "Asia/Magadan",
        "Asia/Makassar",
        "Asia/Manila",
        "Asia/Muscat",
        "Asia/Nicosia",
        "Asia/Novokuznetsk",
        "Asia/Novosibirsk",
        "Asia/Omsk",
        "Asia/Oral",
        "Asia/Phnom_Penh",
        "Asia/Pontianak",
        "Asia/Pyongyang",
        "Asia/Qatar",
        "Asia/Qyzylorda",
        "Asia/Rangoon",
        "Asia/Riyadh",
        "Asia/Saigon",
        "Asia/Sakhalin",
        "Asia/Samarkand",
        "Asia/Seoul",
        "Asia/Shanghai",
        "Asia/Singapore",
        "Asia/Srednekolymsk",
        "Asia/Taipei",
        "Asia/Tashkent",
        "Asia/Tbilisi",
        "Asia/Tehran",
        "Asia/Tel_Aviv",
        "Asia/Thimbu",
        "Asia/Thimphu",
        "Asia/Tokyo",
        "Asia/Tomsk",
        "Asia/Ujung_Pandang",
        "Asia/Ulaanbaatar",
        "Asia/Ulan_Bator",
        "Asia/Urumqi",
        "Asia/Ust-Nera",
        "Asia/Vientiane",
        "Asia/Vladivostok",
        "Asia/Yakutsk",
        "Asia/Yangon",
        "Asia/Yekaterinburg",
        "Asia/Yerevan",
        "Atlantic/Azores",
        "Atlantic/Bermuda",
        "Atlantic/Canary",
        "Atlantic/Cape_Verde",
        "Atlantic/Faeroe",
        "Atlantic/Faroe",
        "Atlantic/Jan_Mayen",
        "Atlantic/Madeira",
        "Atlantic/Reykjavik",
        "Atlantic/South_Georgia",
        "Atlantic/St_Helena",
        "Atlantic/Stanley",
        "Australia/ACT",
        "Australia/Adelaide",
        "Australia/Brisbane",
        "Australia/Broken_Hill",
        "Australia/Canberra",
        "Australia/Currie",
        "Australia/Darwin",
        "Australia/Eucla",
        "Australia/Hobart",
        "Australia/LHI",
        "Australia/Lindeman",
        "Australia/Lord_Howe",
        "Australia/Melbourne",
        "Australia/NSW",
        "Australia/North",
        "Australia/Perth",
        "Australia/Queensland",
        "Australia/South",
        "Australia/Sydney",
        "Australia/Tasmania",
        "Australia/Victoria",
        "Australia/West",
        "Australia/Yancowinna",
        "Brazil/Acre",
        "Brazil/DeNoronha",
        "Brazil/East",
        "Brazil/West",
        "CET",
        "CST6CDT",
        "Canada/Atlantic",
        "Canada/Central",
        "Canada/Eastern",
        "Canada/Mountain",
        "Canada/Newfoundland",
        "Canada/Pacific",
        "Canada/Saskatchewan",
        "Canada/Yukon",
        "Chile/Continental",
        "Chile/EasterIsland",
        "Cuba",
        "EET",
        "EST",
        "EST5EDT",
        "Egypt",
        "Eire",
        "Etc/GMT",
        "Etc/GMT+0",
        "Etc/GMT+1",
        "Etc/GMT+10",
        "Etc/GMT+11",
        "Etc/GMT+12",
        "Etc/GMT+2",
        "Etc/GMT+3",
        "Etc/GMT+4",
        "Etc/GMT+5",
        "Etc/GMT+6",
        "Etc/GMT+7",
        "Etc/GMT+8",
        "Etc/GMT+9",
        "Etc/GMT-0",
        "Etc/GMT-1",
        "Etc/GMT-10",
        "Etc/GMT-11",
        "Etc/GMT-12",
        "Etc/GMT-13",
        "Etc/GMT-14",
        "Etc/GMT-2",
        "Etc/GMT-3",
        "Etc/GMT-4",
        "Etc/GMT-5",
        "Etc/GMT-6",
        "Etc/GMT-7",
        "Etc/GMT-8",
        "Etc/GMT-9",
        "Etc/GMT0",
        "Etc/Greenwich",
        "Etc/UCT",
        "Etc/UTC",
        "Etc/Universal",
        "Etc/Zulu",
        "Europe/Amsterdam",
        "Europe/Andorra",
        "Europe/Astrakhan",
        "Europe/Athens",
        "Europe/Belfast",
        "Europe/Belgrade",
        "Europe/Berlin",
        "Europe/Bratislava",
        "Europe/Brussels",
        "Europe/Bucharest",
        "Europe/Budapest",
        "Europe/Busingen",
        "Europe/Chisinau",
        "Europe/Copenhagen",
        "Europe/Dublin",
        "Europe/Gibraltar",
        "Europe/Guernsey",
        "Europe/Helsinki",
        "Europe/Isle_of_Man",
        "Europe/Istanbul",
        "Europe/Jersey",
        "Europe/Kaliningrad",
        "Europe/Kiev",
        "Europe/Kirov",
        "Europe/Lisbon",
        "Europe/Ljubljana",
        "Europe/London",
        "Europe/Luxembourg",
        "Europe/Madrid",
        "Europe/Malta",
        "Europe/Mariehamn",
        "Europe/Minsk",
        "Europe/Monaco",
        "Europe/Moscow",
        "Europe/Nicosia",
        "Europe/Oslo",
        "Europe/Paris",
        "Europe/Podgorica",
        "Europe/Prague",
        "Europe/Riga",
        "Europe/Rome",
        "Europe/Samara",
        "Europe/San_Marino",
        "Europe/Sarajevo",
        "Europe/Saratov",
        "Europe/Simferopol",
        "Europe/Skopje",
        "Europe/Sofia",
        "Europe/Stockholm",
        "Europe/Tallinn",
        "Europe/Tirane",
        "Europe/Tiraspol",
        "Europe/Ulyanovsk",
        "Europe/Uzhgorod",
        "Europe/Vaduz",
        "Europe/Vatican",
        "Europe/Vienna",
        "Europe/Vilnius",
        "Europe/Volgograd",
        "Europe/Warsaw",
        "Europe/Zagreb",
        "Europe/Zaporozhye",
        "Europe/Zurich",
        "GB",
        "GB-Eire",
        "GMT",
        "GMT+0",
        "GMT-0",
        "GMT0",
        "Greenwich",
        "HST",
        "Hongkong",
        "Iceland",
        "Indian/Antananarivo",
        "Indian/Chagos",
        "Indian/Christmas",
        "Indian/Cocos",
        "Indian/Comoro",
        "Indian/Kerguelen",
        "Indian/Mahe",
        "Indian/Maldives",
        "Indian/Mauritius",
        "Indian/Mayotte",
        "Indian/Reunion",
        "Iran",
        "Israel",
        "Jamaica",
        "Japan",
        "Kwajalein",
        "Libya",
        "MET",
        "MST",
        "MST7MDT",
        "Mexico/BajaNorte",
        "Mexico/BajaSur",
        "Mexico/General",
        "NZ",
        "NZ-CHAT",
        "Navajo",
        "PRC",
        "PST8PDT",
        "Pacific/Apia",
        "Pacific/Auckland",
        "Pacific/Bougainville",
        "Pacific/Chatham",
        "Pacific/Chuuk",
        "Pacific/Easter",
        "Pacific/Efate",
        "Pacific/Enderbury",
        "Pacific/Fakaofo",
        "Pacific/Fiji",
        "Pacific/Funafuti",
        "Pacific/Galapagos",
        "Pacific/Gambier",
        "Pacific/Guadalcanal",
        "Pacific/Guam",
        "Pacific/Honolulu",
        "Pacific/Johnston",
        "Pacific/Kiritimati",
        "Pacific/Kosrae",
        "Pacific/Kwajalein",
        "Pacific/Majuro",
        "Pacific/Marquesas",
        "Pacific/Midway",
        "Pacific/Nauru",
        "Pacific/Niue",
        "Pacific/Norfolk",
        "Pacific/Noumea",
        "Pacific/Pago_Pago",
        "Pacific/Palau",
        "Pacific/Pitcairn",
        "Pacific/Pohnpei",
        "Pacific/Ponape",
        "Pacific/Port_Moresby",
        "Pacific/Rarotonga",
        "Pacific/Saipan",
        "Pacific/Samoa",
        "Pacific/Tahiti",
        "Pacific/Tarawa",
        "Pacific/Tongatapu",
        "Pacific/Truk",
        "Pacific/Wake",
        "Pacific/Wallis",
        "Pacific/Yap",
        "Poland",
        "Portugal",
        "ROC",
        "ROK",
        "Singapore",
        "Turkey",
        "UCT",
        "US/Alaska",
        "US/Aleutian",
        "US/Arizona",
        "US/Central",
        "US/East-Indiana",
        "US/Eastern",
        "US/Hawaii",
        "US/Indiana-Starke",
        "US/Michigan",
        "US/Mountain",
        "US/Pacific",
        "US/Samoa",
        "UTC",
        "Universal",
        "W-SU",
        "WET",
        "Zulu"
    ];
};