export interface BusinessInfoDto {
  business_name: string;
  full_name: string;
  industry: string;
}

export class BusinessNameFieldDto {
  business_name: string;
  constructor(businessName: string) {
    this.business_name = businessName;
  }
  toSring(): string {
    return `{business_name: ${this.business_name}}`;
  }
}
export class EmailFieldDto {
  email: string;
  constructor(email: string) {
    this.email = email;
  }
  toSring(): string {
    return `{email: ${this.email}}`;
  }
}

export type RefreshTokenDto = {
  refreshToken: string;
};

export type RefreshTokenResultDto = {
  refreshToken: string;
  token: string;
};

export type OnboardingBusinessInfoDto = {
  attribute_value:{
  business_name: string;
  full_name: string;
  industry: string;
  businessHours: string;
  services: string;
  address: {
    street: string;
    city: string;
    state: string;
    zipCode: string;
  };
  insuranceList: string;
  doctors: Array<{
    name: string;
    specialization: string;
    bio: string;
    education: string;
  }>;
  team: Array<{
    name: string;
    role: string;
    bio: string;
  }>;
}
synth_agent_id: number;
}
