import { IncomingCallParamsDto } from '@/app/Data/DTO/IncomingCallParamsDto';
import { PlatformService } from '@/app/Utilities/platform/platform.service';
import { Injectable } from '@angular/core';
import { Call, Device, TwilioError } from '@twilio/voice-sdk';
import { auditTime, debounce, interval, Observable, Subject } from 'rxjs';
import { environment } from './../../../environments/environment';
import { ILocalStorageService } from '../Adapters/ILocalStorageService';
import { INPUT_DEVICE, OUTPUT_DEVICE } from '@/app/Utilities/TokenKeys';

enum VoiceClientEvent {
  incoming = 'incoming',
  accept = 'accept',
  cancel = 'cancel',
  disconnect = 'disconnect',
  error = 'error',
  ready = 'ready',
  reject = 'reject',
}

export enum PhoneState {
  LIVE,
  RINGING,
  STANDBY,
}

@Injectable({
  providedIn: 'root',
})
export class VoiceClientService {
  device: Device;
  activeCall: {
    call: Call;
    parentCallSid: string;
  } | undefined;
  // activeCalls: {
  //   call: Call;
  //   parentCallSid: string;
  // }[] = [];

  constructor(
    private platformService: PlatformService,
    private storageService: ILocalStorageService
  ) { }


  readonly onTokenWillExpire$ = new Subject();

  private _onIncomingCall$ = new Subject<IncomingCallParamsDto>();
  // readonly onIncomingCall$ = this._onIncomingCall$
  //   .asObservable()
  //   .pipe(debounce(() => interval(1000)));

  readonly onIncomingCall$ = this._onIncomingCall$
    .asObservable();

  private _onCallReject$ = new Subject<{ parentCallSid: string }>();
  readonly onCallReject$ = this._onCallReject$.asObservable();

  private _onCallDisconnect$ = new Subject<{ parentCallSid: string }>();
  readonly onCallDisconnect$ = this._onCallDisconnect$.asObservable();

  private _onCallCancel$ = new Subject<{ parentCallSid: string }>();
  readonly onCallCancel$ = this._onCallCancel$.asObservable();

  private _onError$ = new Subject<{ parentCallSid: string, error: any }>();
  readonly onError$ = this._onError$.asObservable().pipe(auditTime(1500))

  private _onConnectionError$ = new Subject<{ error: TwilioError.TwilioError, call: Call, parentCallSid: string }>();
  readonly onConnectionError$ = this._onConnectionError$.asObservable()

  private _deviceState$ = new Subject<{ state: PhoneState, parentCallSid: string }>();
  readonly deviceState$ = this._deviceState$.asObservable();

  private _mute$ = new Subject<{ mute: boolean, parentCallSid: string }>();
  readonly mute$ = this._mute$.asObservable();

  displayTime(duration: number): string {
    const hours = Math.trunc(duration / 3600);
    const minutes = Math.trunc((duration % 3600) / 60);
    const seconds = Math.trunc(duration % 60);
    if (hours > 0) {
      return `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : hours
        }:${seconds < 10 ? `0${seconds}` : seconds}`;
    }
    return `${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds
      }`;
  }

  initVoiceClient(token: string) {
    console.log('initVoiceClient :>> ', 'initiating voice client ...');
    try {
      this.resetDeviceState();
      this.device = new Device(token, {
        appName: 'Yobi',
        closeProtection: "A call is currently in progress. Leaving or reloading this page will end the call.",
        allowIncomingWhileBusy: false,
        tokenRefreshMs: 30000,
        appVersion: environment.appVersion,
      });
    } catch (e) {
      console.log('cant register ', e);
      return;
    }
    this.startEvent();
  }

  resetDeviceState() {
    if (this.device) {
      this.device.removeAllListeners();
      this.device.destroy();
    }
  }

  updateToken(token: string): void {
    if (this.device.state != Device.State.Destroyed) {
      this.device?.updateToken(token);
    }
  }

  /* ------------------------------ device events ----------------------------- */
  startEvent(): void {
    this.onRegister();
    this.onTokenExpiring();
    this.onIncomingCall();
    this.onDeviceError();
    this.device.register().catch(e => {
      console.log("could not register ", e);
    });
  }
  onRegister(): void {
    this.device?.on('ready', () => console.log('[voice client] voice is now ready'));
    this.device?.on('registered', () => console.log('[voice client] The app is ready to receive incoming calls.'));
    this.device?.on('unregistered', () => console.log('[voice client] The app is no longer able to receive calls.'));
  }

  onTokenExpiring(): void {
    this.device.on('tokenWillExpire', () => {
      console.log('clg twiliio token expiring');
      this.onTokenWillExpire$?.next(true)
    });
  }

  onIncomingCall(): void {
    this.device.on(VoiceClientEvent.incoming, (call: Call) => {
      this.acceptIncomingCall(call);
      setTimeout(() => {
        call.reject();
      }, 30000);
    });
  }

  acceptIncomingCall(call: Call) {
    const parentCallSid = call.customParameters.get('parentCallSid')!;
    this.onDeviceStateChange(PhoneState.RINGING, parentCallSid);
    const data: IncomingCallParamsDto = {
      parentCallSid,
      from: call.parameters['From'],
      avatar: call.customParameters.get('avatar') || undefined,
      displayName: call.customParameters.get('contactName') || '',
      channelValue: call.customParameters.get('Number') || '',
      isTransfer: call.customParameters.get('Type') === 'transfer',
    };

    // this.activeCalls.push({ call, parentCallSid });
    this.activeCall = { call, parentCallSid }
    this._onIncomingCall$.next(data);
    this.listenForCallEvents(parentCallSid);
  }

  onDeviceError(): void {
    this.device.on(VoiceClientEvent.error, (twilioError, call) => {
      this._onError$.next({ parentCallSid: call?.customParameters?.get('parentCallSid')!, error: twilioError });
    });
  }

  makeCall({ to, To, callerId, intent }: {
    To: string;
    to: string;
    callerId: string;
    intent: string;
  }) {
    this.device?.connect({
      params: {
        to, To, callerId, intent,
        device: this.platformService.device,
        appVersion: environment.appVersion
      },
    }).then((activeCall: Call) => {
      // this.activeCalls.push({ call: activeCall, parentCallSid: activeCall.customParameters.get('parentCallSid')! });
      this.activeCall = { call: activeCall, parentCallSid: activeCall.customParameters.get('parentCallSid')! }
      this.listenForCallEvents(activeCall.customParameters.get('parentCallSid')!);
    }).catch(console.error);
  }

  /* ------------------------------- Call events ------------------------------ */
  listenForCallEvents(parentCallSid: string): void {
    this.onAccept(parentCallSid);
    this.onReject(parentCallSid);
    this.onCancel(parentCallSid);
    this.onError(parentCallSid)
    this.onDisconnect(parentCallSid);
  }

  onAccept(parentCallSid: string): void {
    // this.activeCalls.find(c => c.parentCallSid === parentCallSid)?.call.on(VoiceClientEvent.accept, (call: Call) => {
    this.activeCall?.call.on(VoiceClientEvent.accept, (call: Call) => {
      console.log('accepting :>> ', call);
      this.onDeviceStateChange(PhoneState.LIVE, parentCallSid);
    });
  }
  onReject(parentCallSid: string): void {//
    // this.activeCalls.find(c => c.parentCallSid === parentCallSid)?.call.on(VoiceClientEvent.reject, (call: Call) => {
    this.activeCall?.call.on(VoiceClientEvent.reject, (call: Call) => {
      console.log('rejecting :>>', { parentCallSid }, this.activeCall);
      this.onDeviceStateChange(PhoneState.STANDBY, parentCallSid);
      this._onCallReject$.next({ parentCallSid });
      this.clearCall(parentCallSid)
    });
  }
  onCancel(parentCallSid: string): void {//
    // this.activeCalls.find(c => c.parentCallSid === parentCallSid)?.call.on(VoiceClientEvent.cancel, (call: Call) => {
    this.activeCall?.call.on(VoiceClientEvent.cancel, (call: Call) => {
      console.log('canceling :>>', { parentCallSid }, this.activeCall);
      this.onDeviceStateChange(PhoneState.STANDBY, parentCallSid);
      this._onCallCancel$.next({ parentCallSid });
      this.clearCall(parentCallSid)
    });
  }
  onDisconnect(parentCallSid: string): void {//
    // this.activeCalls.find(c => c.parentCallSid === parentCallSid)?.call.on(VoiceClientEvent.disconnect, (call: Call) => {
    this.activeCall?.call.on(VoiceClientEvent.disconnect, (call: Call) => {
      console.log('disconnecting :>>', { parentCallSid }, this.activeCall);
      this.onDeviceStateChange(PhoneState.STANDBY, parentCallSid);
      this._onCallDisconnect$.next({ parentCallSid });
      this.clearCall(parentCallSid)
    });
  }
  onError(parentCallSid: string): void {//
    // this.activeCalls.find(c => c.parentCallSid === parentCallSid)?.call.on(VoiceClientEvent.error, (error: TwilioError.TwilioError) => {
    this.activeCall?.call.on(VoiceClientEvent.error, (error: TwilioError.TwilioError) => {
      // const call = this.activeCalls.find(c => c.parentCallSid === parentCallSid)!.call
      const call = this.activeCall!.call
      call.disconnect()
      this._onConnectionError$.next({ error, call, parentCallSid })
      this.clearCall(parentCallSid)
    })
  }
  unregister(): void {
    if (this.device?.state == Device.State.Registered) {
      this.device?.unregister()
        .catch(console.error);
    }
  }
  destroy(): void {
    this.device?.destroy();
  }

  onDeviceStateChange(state: PhoneState, parentCallSid: string): void {
    this._deviceState$.next({ state, parentCallSid });
  }

  ignore(parentCallSid: string): void {
    // this.activeCalls.find(c => c.parentCallSid === parentCallSid)?.call.ignore();
    this.activeCall?.call.ignore();
  }

  reject(parentCallSid: string): void {
    // this.activeCalls.find(c => c.parentCallSid === parentCallSid)?.call.reject()
    this.activeCall?.call.reject()
  }

  accept(parentCallSid: string): void {
    // this.activeCalls.find(c => c.parentCallSid === parentCallSid)?.call.accept();
    this.activeCall?.call.accept();
  }

  hangup(parentCallSid: string): void {
    // this.activeCalls.find(c => c.parentCallSid === parentCallSid)?.call.disconnect();
    this.activeCall?.call.disconnect();
  }

  sendDigit(parentCallSid: string, digit: string): void {
    // this.activeCalls.find(c => c.parentCallSid === parentCallSid)?.call.sendDigits(String(digit));
    this.activeCall?.call.sendDigits(String(digit));
  }

  toggleMute(parentCallSid: string): void {
    // const activeCall = this.activeCalls.find(c => c.parentCallSid === parentCallSid)?.call!
    const activeCall = this.activeCall!
    activeCall.call.mute(!activeCall.call.isMuted());
    this._mute$.next({ mute: activeCall.call.isMuted(), parentCallSid });
  }

  clearCall(parentCallSid: string): void {
    // const activeCall = this.activeCalls.find(c => c.parentCallSid === parentCallSid)?.call!
    const activeCall = this.activeCall
    activeCall?.call.removeAllListeners();
    // this.activeCalls = this.activeCalls.filter(c => c.parentCallSid !== parentCallSid);
    this.activeCall = undefined
  }

  /* ------------------------------ Audio devoces ----------------------------- */

  inputDeviceId: string;

  async setAudioInputDevice(deviceId: string | undefined, force?: boolean) {
    deviceId = deviceId ?? this.inputDeviceId
    if (deviceId) {
      this.storageService.setItem(INPUT_DEVICE, deviceId);
      if (this.activeCall ?? force) {
        await this.device?.audio?.setInputDevice(deviceId)
        this.inputDeviceId = ''
      } else {
        this.inputDeviceId = deviceId
      }
    }
  }

  setAudioOutputDevice(deviceId: string) {
    this.storageService.setItem(OUTPUT_DEVICE, deviceId);
    this.device?.audio?.ringtoneDevices.set([deviceId]).catch(() => { });
    this.device?.audio?.speakerDevices.set([deviceId]).catch(() => { });
  }
}
