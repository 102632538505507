import { Injectable } from '@angular/core';

import { ITaskRepository } from '@/app/core/IRepositories/ITaskepository';

import { CompaniesViewModel } from '@/app/Ui/ViewModels/companiesViewModel';
import { ContactsViewModel } from '@/app/Ui/ViewModels/contactsViewModel';

import { UseCase } from '../UseCase';
import { Task, TaskDetail } from '@/app/core/Models/Task';
import { CreatTaskDto } from '@/app/Data/DTO/TaskDto';

import { Observable, tap } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class CreateTaskUseCase implements UseCase<CreatTaskDto, TaskDetail> {
  constructor(
    private companiesViewModel: CompaniesViewModel,
    private contactsViewModel: ContactsViewModel,
    private taskRepository: ITaskRepository
  ) { }

  execute(request: CreatTaskDto): Observable<TaskDetail> {
    return this.taskRepository.createTask(request.task).pipe(
      tap(async (task) => {
        this.readAssociatedTasks(request);
        const _t = { ...task, created_dt_timestamp: new Date(task.created_dt).getTime() };
        this.taskRepository.saveTaskDetail(_t as TaskDetail);
        this.taskRepository.isUnread().subscribe();
      })
    );
  }

  readAssociatedTasks(request: CreatTaskDto): void {
    if (request.task.yobi_crm_company_id) this.companiesViewModel.getCompanyTasks({ page: 1, page_size: 20, companyId: request.task.yobi_crm_company_id as string });
    if (request.task.contact_id) this.contactsViewModel.getContactTasks({ page: 1, page_size: 20, contact_id: request.task.contact_id as string });
  }

}
