import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, firstValueFrom, map } from 'rxjs';
import { SynthViewModel } from '../../../Ui/ViewModels/synth-view-model';

export interface TourStep {
  targetId: string;
  title: string;
  content: string;
  videoUrl?: string; // Optional video URL
}

@Injectable({
  providedIn: 'root'
})
export class TourService {
  private currentStepIndex = new BehaviorSubject<number>(0);
  private isActive = new BehaviorSubject<boolean>(false);
  private overlay: HTMLElement | null = null;
  private tooltip: HTMLElement | null = null;
  private readonly TOUR_STATE_KEY = 'tour_state';
  private readonly TOUR_COMPLETED_KEY = 'tour_completed';

  constructor(
    private router: Router,
    private synthViewModel: SynthViewModel
  ) {
    this.loadTourState();
    window.addEventListener('popstate', async () => {
        // Clean up existing elements
        this.removeOverlay();
        this.removeTooltip();
        
        // Check if tour is active and restore the current step
        const savedState = localStorage.getItem(this.TOUR_STATE_KEY);
        if (savedState) {
            const state = JSON.parse(savedState);
            if (state.isActive) {
                // Wait briefly for the DOM to update
                setTimeout(() => {
                    this.currentStepIndex.next(state.currentStep);
                    this.isActive.next(true);
                    this.showCurrentStep();
                }, 500);
            }
        }
    });
  }

  private loadTourState() {
    // Clean up any existing overlays or tooltips that might be leftover
    this.removeOverlay();
    this.removeTooltip();

    const tourCompleted = localStorage.getItem(this.TOUR_COMPLETED_KEY);
    if (tourCompleted === 'true') {
      this.isActive.next(false);
      return;
    }

    const savedState = localStorage.getItem(this.TOUR_STATE_KEY);
    if (savedState) {
      const state = JSON.parse(savedState);
      this.currentStepIndex.next(state.currentStep);
      this.isActive.next(state.isActive);
      
      if (state.isActive) {
        // Clean up any existing elements before restarting
        const currentStep = this.steps[state.currentStep];
        const existingElement = document.getElementById(currentStep.targetId);
        if (existingElement) {
          this.cleanupHighlight(existingElement);
        }
        
        // If tour was active, restart from saved step with a slight delay
        setTimeout(() => this.showCurrentStep(), 1000);
      }
    }
  }

  private saveTourState() {
    const state = {
      currentStep: this.currentStepIndex.value,
      isActive: this.isActive.value
    };
    localStorage.setItem(this.TOUR_STATE_KEY, JSON.stringify(state));
  }

  readonly steps: TourStep[] = [
    {
      targetId: 'dashboard-welcome',
      title: 'Select AI Teammate',
      content: 'Click this button to select your AI teammate.',
      videoUrl: 'https://www.youtube.com/embed/S9utgdgyb04?si=_OG4vqzid8veHcNy&autoplay=1&playsinline=1&enablejsapi=1'
    },
    {
      targetId: 'train-ai-button',
      title: 'Train Your AI',
      content: 'Now, click here to start training your AI teammate with your business knowledge.',
      videoUrl: 'https://www.youtube.com/embed/FqPSsGnZ748?si=oxnLECQLtkpkUYho&autoplay=1'
    },
    {
      targetId: 'talk-to-train-button',
      title: 'Talk to Train',
      content: 'Finally, click here to start training your AI through conversation.',
      videoUrl: 'https://www.youtube.com/embed/UdYuzI2qdDA?si=PCfc8nNnBJym3gM3&autoplay=1'
    },
    // {
    //   targetId: 'click-on-conversation-0',
    //   title: 'Start Conversation',
    //   content: 'Click here to begin your conversation with the AI.',
    //   videoUrl: 'https://www.youtube.com/embed/vuCgg3qbG74?si=nfpjg2n6t3Fc-_we&autoplay=1'
    // },
    {
      targetId: 'apply-changes',
      title: 'Apply Changes',
      content: 'placeholder'
    },
    {
      targetId: 'test-agent',
      title: 'Test Your AI',
      content: 'Test your AI teammate to ensure it has learned your business knowledge correctly.'
      // videoUrl: 'https://www.youtube.com/embed/pVRIYGhYyts?si=yNxm0-zTGemqNzzV&autoplay=1'
    },
    {
      targetId: 'test-your-agent',
      title: 'Start Testing',
      content: 'Begin testing your AI teammate with real-world scenarios to verify its training.',
      videoUrl: 'https://www.youtube.com/embed/SF8K_4sQbbI?si=9krhFr4BzeRpK9nL&autoplay=1'
    },
    {
      targetId: 'end-testcall',
      title: 'End Call',
      content: 'When you are done testing, click here to end the call.'
    },
    {
      targetId: 'dashboard-welcome',
      title: 'Share Agent',
      content: 'Click here to share your AI teammate with your team/friends.'
    }

  ];

  async startTour() {
    console.log('Tour starting...');
    this.isActive.next(true);
    this.currentStepIndex.next(0);
    this.saveTourState();
    
    // Add initial delay of 1.5 seconds
    
    // Wait for first element to appear before starting the tour
    const firstStepId = this.steps[0].targetId;
    await this.waitForElement(firstStepId);
    // window.location.reload();
    await this.showCurrentStep();
  }

  private async showCurrentStep() {
    const currentStep = this.steps[this.currentStepIndex.value];
    
    // Remove overlay while waiting for element
    this.removeOverlay();
    this.removeTooltip();
    
    // Wait for element to appear for all steps
    await this.waitForElement(currentStep.targetId);
    
    const targetElement = document.getElementById(currentStep.targetId);
    if (!targetElement) {
      console.error(`Target element ${currentStep.targetId} not found`);
      return;
    }

    // Special handling for end-testcall step
    if (currentStep.targetId === 'end-testcall') {
      let hasInteracted = false;
      
      // Add click handler before showing tooltip
      const originalClick = targetElement.onclick;
      targetElement.onclick = async (e) => {
        hasInteracted = true;
        if (originalClick) {
          originalClick.call(targetElement, e);
        }
        
        // Navigate to the desired page
        await this.router.navigate(['main/workshop']);
        
        // Add a delay after navigation to ensure the last step is visible
        await new Promise(resolve => setTimeout(resolve, 2000));

        if (this.currentStepIndex.value < this.steps.length - 1) {
          this.currentStepIndex.next(this.currentStepIndex.value + 1);
          await this.showCurrentStep();
        }
      };

      // Wait 15 seconds before showing tooltip
      await new Promise(resolve => setTimeout(resolve, 15000));
      
      // Check if the tour is still active and user hasn't interacted
      if (!this.isActive.value || hasInteracted) return;
    } else if (currentStep.targetId === 'test-agent') {
      // Wait 3 seconds without overlay or highlighting
      await new Promise(resolve => setTimeout(resolve, 3000));
      
      // Check if the tour is still active after delay
      if (!this.isActive.value) return;
    } else if (currentStep.targetId === 'dashboard-welcome' && this.currentStepIndex.value === this.steps.length - 1) {
      const card = targetElement;
      
      // Create a single handler for ending the tour
      const handleTourEnd = (e: Event) => {
        e.stopPropagation();
        this.cleanupHighlight(targetElement);
        this.endTour();
      };

      // Add click handler to the entire card first
      card.addEventListener('click', handleTourEnd, true);

      // Add click handlers to all interactive elements
      const interactiveElements = card.querySelectorAll('button, svg-icon, .avatar-circle, .select-button, .share-button, .delete-icon');
      interactiveElements.forEach(element => {
        element.addEventListener('click', handleTourEnd, true);
      });

      // Store the handlers for cleanup
      (card as any).__tourHandlers = {
        card: handleTourEnd,
        elements: interactiveElements
      };
    }

    // Get the synth_id of the first synth in the list
    

    // Create overlay and highlight element after any delays
    this.createOverlay();
    this.highlightElement(targetElement);
    this.createTooltip(targetElement, currentStep);
    this.saveTourState();
  }

  private showEndingPrompt(): Promise<boolean> {
    return new Promise((resolve) => {
      const promptDiv = document.createElement('div');
      promptDiv.style.cssText = `
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: white;
        padding: 20px;
        border-radius: 4px;
        box-shadow: 0 2px 8px rgba(0,0,0,0.15);
        z-index: 10001;
        text-align: center;
        font-size: 18px;
        color: #333;
        display: flex;
        flex-direction: column;
        gap: 15px;
      `;
      
      promptDiv.innerHTML = `
        <div>Would you like to end the tour?</div>
        <div style="display: flex; gap: 10px; justify-content: center;">
          <button id="end-tour-yes" style="padding: 8px 16px; background: #f44336; color: white; border: none; border-radius: 4px; cursor: pointer;">Yes</button>
          <button id="end-tour-no" style="padding: 8px 16px; background: #4CAF50; color: white; border: none; border-radius: 4px; cursor: pointer;">No</button>
        </div>
      `;
      
      document.body.appendChild(promptDiv);

      const yesButton = promptDiv.querySelector('#end-tour-yes');
      const noButton = promptDiv.querySelector('#end-tour-no');

      yesButton?.addEventListener('click', () => {
        document.body.removeChild(promptDiv);
        resolve(true);
      });

      noButton?.addEventListener('click', () => {
        document.body.removeChild(promptDiv);
        resolve(false);
      });
    });
  }

  private waitForElement(elementId: string): Promise<void> {
    return new Promise((resolve) => {
      const checkElement = () => {
        const element = document.getElementById(elementId);
        if (element) {
          resolve();
        } else {
          requestAnimationFrame(checkElement);
        }
      };
      checkElement();
    });
  }

  private showEndingMessage() {
    const messageDiv = document.createElement('div');
    messageDiv.style.cssText = `
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: white;
      padding: 20px;
      border-radius: 4px;
      box-shadow: 0 2px 8px rgba(0,0,0,0.15);
      z-index: 10001;
      text-align: center;
      font-size: 18px;
      color: #333;
    `;
    messageDiv.textContent = 'Tour ending...';
    document.body.appendChild(messageDiv);

    // Change text after 1 second
    setTimeout(() => {
      messageDiv.textContent = 'Tour ended';
    }, 1000);

    // Remove message after 2 seconds
    setTimeout(() => {
      document.body.removeChild(messageDiv);
    }, 2000);
  }

  private highlightElement(element: HTMLElement) {
    element.style.setProperty('position', 'relative', 'important');
    element.style.setProperty('z-index', '9999', 'important');
    element.style.setProperty('transform', 'scale(1.1)', 'important');
    element.style.setProperty('transition', 'transform 0.3s ease-out', 'important');
    element.style.setProperty('pointer-events', 'auto', 'important');
    element.style.setProperty('outline', 'none', 'important');
    element.style.setProperty('border', 'none', 'important');
    element.style.setProperty('box-shadow', 'none', 'important');
  }

  private createOverlay() {
    if (this.overlay) {
      this.removeOverlay();
    }

    this.overlay = document.createElement('div');
    this.overlay.style.cssText = `
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.3);
      z-index: 9998;
      pointer-events: auto;
    `;
    
    this.overlay.addEventListener('click', (e) => {
      if (e.target === this.overlay) {
        this.showEndTourPrompt();
      }
    });

    if (this.overlay) {
      document.body.appendChild(this.overlay);
    }
  }

  private showEndTourPrompt() {
    const promptDiv = document.createElement('div');
    promptDiv.style.cssText = `
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: white;
      padding: 24px;
      border-radius: 12px;
      box-shadow: 0 8px 24px rgba(0,0,0,0.15);
      z-index: 10001;
      text-align: center;
      min-width: 300px;
    `;

    promptDiv.innerHTML = `
      <h3 style="margin: 0 0 16px; font-size: 18px; color: #333; font-weight: 500;">End Tour?</h3>
      <p style="margin: 0 0 24px; font-size: 14px; color: #666; line-height: 1.5;">
        Are you sure you want to end the guided tour?
      </p>
      <div style="display: flex; gap: 12px; justify-content: center;">
        <button id="endTourNo" style="
          padding: 8px 24px;
          border: 1px solid #6200ee;
          background: white;
          color: #6200ee;
          border-radius: 6px;
          font-size: 14px;
          cursor: pointer;
          transition: all 0.2s;
          font-weight: 500;
        ">Continue Tour</button>
        <button id="endTourYes" style="
          padding: 8px 24px;
          border: none;
          background: #6200ee;
          color: white;
          border-radius: 6px;
          font-size: 14px;
          cursor: pointer;
          transition: all 0.2s;
          font-weight: 500;
        ">End Tour</button>
      </div>
    `;

    document.body.appendChild(promptDiv);

    // Add hover effects
    const yesButton = promptDiv.querySelector('#endTourYes') as HTMLElement;
    const noButton = promptDiv.querySelector('#endTourNo') as HTMLElement;

    if (yesButton) {
      yesButton.onmouseover = () => yesButton.style.background = '#3700b3';  // Darker purple on hover
      yesButton.onmouseout = () => yesButton.style.background = '#6200ee';
    }

    if (noButton) {
      noButton.onmouseover = () => {
        noButton.style.background = '#f8f9fa';
        noButton.style.borderColor = '#3700b3';
        noButton.style.color = '#3700b3';
      };
      noButton.onmouseout = () => {
        noButton.style.background = 'white';
        noButton.style.borderColor = '#6200ee';
        noButton.style.color = '#6200ee';
      };
    }

    yesButton?.addEventListener('click', () => {
      document.body.removeChild(promptDiv);
      this.endTour();
    });

    noButton?.addEventListener('click', () => {
      document.body.removeChild(promptDiv);
    });
  }

  private createTooltip(targetElement: HTMLElement, step: TourStep) {
    if (this.tooltip) {
      this.removeTooltip();
    }

    this.tooltip = document.createElement('div');
    this.tooltip.style.cssText = `
      position: absolute;
      background: white;
      padding: 20px;
      border-radius: 12px;
      box-shadow: 0 4px 16px rgba(0,0,0,0.1);
      z-index: 10000;
      max-width: ${step.videoUrl ? '400px' : '300px'};
      pointer-events: auto;
      border: 1px solid rgba(0,0,0,0.1);
    `;

    // Create arrow element
    const arrow = document.createElement('div');
    arrow.style.cssText = `
      position: absolute;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid white;
      top: -8px;
      left: 20px;
      filter: drop-shadow(0 -1px 1px rgba(0,0,0,0.1));
    `;
    this.tooltip.appendChild(arrow);

    const content = document.createElement('div');
    
    // Add step counter
    const stepCounter = `
      <div style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 12px;">
        <span style="font-size: 12px; color: #666;">Step ${this.currentStepIndex.value + 1}/${this.steps.length}</span>
        <div style="display: flex; gap: 4px;">
          ${this.steps.map((_, index) => `
            <div style="
              width: 8px;
              height: 8px;
              border-radius: 50%;
              background-color: ${index === this.currentStepIndex.value ? '#4285f4' : '#e0e0e0'};
              transition: background-color 0.3s ease;
            "></div>
          `).join('')}
        </div>
      </div>
    `;
    
    // Get dynamic content for apply-changes step
    let stepContent = step.content;
    if (step.targetId === 'apply-changes') {
      stepContent = this.synthViewModel.isDummyChange.value 
        ? 'Looks like you didnt request any changes, Here is a dummy change to illustrate the training process. Click here to apply your changes and update your AI teammate.'
        : 'Click here to apply your changes and update your AI teammate.';
    }

    // Update the innerHTML with dynamic content
    let innerHTML = `
      ${stepCounter}
      <h3 style="margin: 0 0 10px; font-size: 16px; color: #333;">${step.title}</h3>
      <p style="margin: 0 0 ${step.videoUrl ? '10px' : '0'}; font-size: 14px; color: #666; line-height: 1.5;">${stepContent}</p>
    `;

    // Add video if URL is provided
    if (step.videoUrl) {
      innerHTML += `
        <div style="margin-top: 15px; position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; border-radius: 8px;">
          <iframe 
            src="${step.videoUrl}"
            style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; border: none;"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen>
          </iframe>
        </div>
      `;
    }

    // Add a select button for step 1
    if (this.currentStepIndex.value === 0) {
      innerHTML += `
        <button id="select-button" style="margin-top: 10px; padding: 8px 16px; background: #4285f4; color: white; border: none; border-radius: 4px; cursor: pointer;">
          Select
        </button>
      `;
    }

    content.innerHTML = innerHTML;
    this.tooltip.appendChild(content);
    document.body.appendChild(this.tooltip);

    // Add event listener for the select button if it's step 1
    if (this.currentStepIndex.value === 0) {
      const selectButton = this.tooltip.querySelector('#select-button');
      selectButton?.addEventListener('click', async () => {
        const synthSelectButton = targetElement.querySelector('.select-button');
        if (synthSelectButton) {
          // Force navigation to workshop page
          
          const firstSynth = await firstValueFrom(this.synthViewModel.synthsList$.pipe(
            map(synths => synths && synths.length > 0 ? synths[0] : null)
          ));
          const firstSynthId = firstSynth?.synth_id;
          console.warn('First Synth ID:', firstSynth);
          
          const successNavigation = await this.router.navigate([`main/workshop/${firstSynthId}`]);
          
          // Clean up current step
          this.cleanupHighlight(targetElement);
          this.removeTooltip();
          
          // Move to next step after a short delay
          await new Promise(resolve => setTimeout(resolve, 300));
          
          if (successNavigation && this.currentStepIndex.value < this.steps.length - 1) {
            this.currentStepIndex.next(this.currentStepIndex.value + 1);
            await this.showCurrentStep();
          }
        }
      });
    }

    // Modified click handler with optimized timing
    const originalClick = targetElement.onclick;
    targetElement.onclick = async (e) => {
      if (this.isActive.value) {
        if (originalClick) {
          originalClick.call(targetElement, e);
        }
        
        await new Promise(resolve => setTimeout(resolve, 100));
        
        this.cleanupHighlight(targetElement);
        this.removeTooltip();
        
        // Special handling for transition between steps 3 and 4
        if (this.currentStepIndex.value === 2) {
          this.removeOverlay();
          this.isActive.next(false);
          this.currentStepIndex.next(3);
          
          // Start watching for fourth element
          const fourthStepId = this.steps[3].targetId;
          this.waitForElement(fourthStepId).then(() => {
            this.isActive.next(true);
            
            this.showCurrentStep();
          });
        } else {
          if (this.currentStepIndex.value === this.steps.length - 1) {
            this.removeOverlay();
          }
          
          await new Promise(resolve => setTimeout(resolve, 300));
          
          if (this.currentStepIndex.value < this.steps.length - 1) {
            this.currentStepIndex.next(this.currentStepIndex.value + 1);
            await this.showCurrentStep();
          } else {
            this.endTour();
          }
        }
      } else if (originalClick) {
        originalClick.call(targetElement, e);
      }
    };

    (targetElement as any).__originalClick = originalClick;
    this.positionTooltip(targetElement);
  }

  private positionTooltip(targetElement: HTMLElement) {
    if (!this.tooltip) return;

    const rect = targetElement.getBoundingClientRect();
    const tooltipRect = this.tooltip.getBoundingClientRect();
    const margin = 10;
    const arrow = this.tooltip.firstChild as HTMLElement;

    // Initial position calculation
    let top = rect.bottom + margin;
    let left = rect.left;
    let arrowPosition = 'top';

    // Check vertical positioning
    if (top + tooltipRect.height > window.innerHeight) {
      // If tooltip would go off bottom, try placing it above
      if (rect.top - tooltipRect.height - margin > 0) {
        top = rect.top - tooltipRect.height - margin;
        arrowPosition = 'bottom';
      } else {
        // If not enough space above, try placing it to the right
        if (rect.right + tooltipRect.width + margin < window.innerWidth) {
          top = rect.top;
          left = rect.right + margin;
          arrowPosition = 'left';
        } else if (rect.left - tooltipRect.width - margin > 0) {
          // Try placing it to the left
          top = rect.top;
          left = rect.left - tooltipRect.width - margin;
          arrowPosition = 'right';
        } else {
          // If no space on sides either, center vertically
          top = (window.innerHeight - tooltipRect.height) / 2;
          arrowPosition = 'left';
        }
      }
    }

    // Check horizontal positioning
    if (left + tooltipRect.width > window.innerWidth - margin) {
      left = window.innerWidth - tooltipRect.width - margin;
    }
    if (left < margin) {
      left = margin;
    }

    // Position the arrow based on final position
    const arrowLeft = Math.min(
      Math.max(
        rect.left - left + (rect.width / 2) - 8,
        16
      ),
      tooltipRect.width - 16
    );

    // Update arrow styles based on position
    if (arrowPosition === 'top') {
      arrow.style.cssText = `
        position: absolute;
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid white;
        top: -8px;
        left: ${arrowLeft}px;
      `;
    } else if (arrowPosition === 'bottom') {
      arrow.style.cssText = `
        position: absolute;
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 8px solid white;
        bottom: -8px;
        left: ${arrowLeft}px;
      `;
    } else if (arrowPosition === 'left') {
      arrow.style.cssText = `
        position: absolute;
        width: 0;
        height: 0;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-right: 8px solid white;
        left: -8px;
        top: ${(tooltipRect.height / 2) - 8}px;
      `;
    } else if (arrowPosition === 'right') {
      arrow.style.cssText = `
        position: absolute;
        width: 0;
        height: 0;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-left: 8px solid white;
        right: -8px;
        top: ${(tooltipRect.height / 2) - 8}px;
      `;
    }

    // Apply final position
    this.tooltip.style.top = `${Math.max(margin, Math.min(top, window.innerHeight - tooltipRect.height - margin))}px`;
    this.tooltip.style.left = `${left}px`;
  }

  private endTour() {
    const currentElement = document.getElementById(this.steps[this.currentStepIndex.value].targetId);
    if (currentElement) {
        this.cleanupHighlight(currentElement);
    }
    
    this.isActive.next(false);
    this.removeOverlay();
    this.removeTooltip();
    
    // Save the current step index and active state before resetting
    this.saveTourState();
    
    // Reset the current step index only if the tour is explicitly ended
    this.currentStepIndex.next(0);
    
    // Mark tour as completed
    localStorage.setItem(this.TOUR_COMPLETED_KEY, 'true');
    localStorage.removeItem(this.TOUR_STATE_KEY);
  }

  // Add method to reset tour state
  public resetTour() {
    localStorage.removeItem(this.TOUR_COMPLETED_KEY);
    localStorage.removeItem(this.TOUR_STATE_KEY);
    this.currentStepIndex.next(0);
    this.isActive.next(false);
  }

  private cleanupHighlight(element: HTMLElement) {
    if ((element as any).__tourInterval) {
      clearInterval((element as any).__tourInterval);
      delete (element as any).__tourInterval;
    }
    
    if ((element as any).__originalClick) {
      element.onclick = (element as any).__originalClick;
      delete (element as any).__originalClick;
    }
    
    // Clean up viewport styles if it exists
    const viewport = element.closest('cdk-virtual-scroll-viewport');
    if (viewport) {
      (viewport as HTMLElement).style.removeProperty('position');
      (viewport as HTMLElement).style.removeProperty('z-index');
    }
    
    element.classList.remove('tour-highlight');
    element.style.removeProperty('position');
    element.style.removeProperty('z-index');
    element.style.removeProperty('transform');
    element.style.removeProperty('transition');
    element.style.removeProperty('pointer-events');
    element.style.removeProperty('outline');
    element.style.removeProperty('border');
    element.style.removeProperty('box-shadow');

    // Clean up tour end handlers
    if ((element as any).__tourHandlers) {
      element.removeEventListener('click', (element as any).__tourHandlers.card, true);
      (element as any).__tourHandlers.elements.forEach((el: HTMLElement) => {
        el.removeEventListener('click', (element as any).__tourHandlers.card, true);
      });
      delete (element as any).__tourHandlers;
    }
  }

  private removeOverlay() {
    if (this.overlay && this.overlay.parentNode) {
      this.overlay.parentNode.removeChild(this.overlay);
      this.overlay = null;
    }
  }

  private removeTooltip() {
    if (this.tooltip && this.tooltip.parentNode) {
      this.tooltip.parentNode.removeChild(this.tooltip);
      this.tooltip = null;
    }
  }

  get isActive$() {
    return this.isActive.asObservable();
  }

  get currentStep$() {
    return this.currentStepIndex.asObservable();
  }
}

