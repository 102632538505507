import { InternalMessageStatusDto } from "@/app/Data/DTO/InternalMessageStatusDto";
import { SuccessResponse } from "@/app/Data/DTO/successResponse";
import { ICreateRoomResponse, InputCreateRoom, IParticipantResponse, IRoomResponse, TeamChatMessageDto } from "@/app/Data/DTO/TeamChatMessageDto";
import { TeamChatThreadMessagesQueryDto } from "@/app/Data/DTO/TeamChatThreadMessagesQueryDto";
import { IRoom } from "@/app/Ui/team-chat-center/team-chat-room/team-chat-room.interface";
import { Observable, Observer } from "rxjs";
import { InteractionStatus, InteractionThread, InteractionTranscription, RecentInteraction, RecentMessagesQuery, RecentMessagesQueryParams } from "../Models/Interaction";
import { TeamInteraction } from "../Models/interaction-team";

export abstract class ITeamInteractionRepository {
    abstract sendMessage(
        data: TeamChatMessageDto
    ): Observable<TeamInteraction>

    abstract deleteMessage(
        interaction_id: number
    ): Observable<TeamInteraction>

    abstract reactToMessage(
        interaction_id: number,
        reaction: string
    ): Observable<{ reaction: string, user_id: string | number }>

    abstract updateMessageStatus(
        data: { interation_id: number, data: InternalMessageStatusDto }
    ): Observable<TeamInteraction>

    abstract getRecentMessages(
        params: RecentMessagesQueryParams
    ): Observable<TeamInteraction[]>

    abstract getInteractions(
        query: TeamChatThreadMessagesQueryDto
    ): Observable<TeamInteraction[]>

    abstract getUserMessages(
        user_id: number,
        query: RecentMessagesQuery
    ): Observable<TeamInteraction<string>[]>

    abstract saveTeamRecentMessages(
        data: RecentInteraction[],
        params: RecentMessagesQueryParams,
    ): void

    abstract saveTeamRecentMessage(data: RecentInteraction): void

    abstract getLocalTeamRecentMessages(
    ): Observable<RecentInteraction[]>

    abstract saveTeamThreadMessages(
        data: InteractionThread[],
        page: { page: number; userId: number }
    ): void;

    abstract saveTeamThreadMessage(data: InteractionThread): void
    abstract setLocalTeamThreadInteraction(interaction: InteractionThread): void
    abstract updateLocalTeamThreadInteraction(data: Partial<InteractionThread>, interactionId: string): void

    abstract getLocalTeamMessages(
        query: TeamChatThreadMessagesQueryDto
    ): Observable<InteractionThread[]>;

    abstract updateLocalRoomRecentInteractions(room: IRoom): void;

    abstract getLocalTeamMessage(interactionId: string): Observable<InteractionThread | undefined>;

    abstract createRoom(data: InputCreateRoom): Observable<ICreateRoomResponse>
    abstract updateRoom(room_id: string, data: InputCreateRoom): Observable<ICreateRoomResponse>
    abstract updateRoomImage(room_id: string, data: FormData, progress$: Observer<any>): Observable<{ avatar: string }>
    abstract addRoomPartcipant(room_id: string, participants: (string | number)[]): Observable<IParticipantResponse>
    abstract fetchRoomParticipant(room_id: string): Observable<IRoomResponse>
    abstract removeRoomParticipant(room_id: string, participant_id: string): Observable<IParticipantResponse>
    abstract muteRoomNotification(room_id: string, status: 'ON' | 'OFF'): Observable<SuccessResponse>
    abstract fetchRoomMessages(query: TeamChatThreadMessagesQueryDto): Observable<TeamInteraction[]>
    abstract fetchRoomNotifications(room_id: string): Observable<SuccessResponse>
    abstract fetchRoomParticipantsNotifications(room_id: string): Observable<SuccessResponse>
    abstract fetchRoomThreadMessages(query: TeamChatThreadMessagesQueryDto): Observable<TeamInteraction[]>
    abstract fetchTenantRooms(): Observable<IRoom[]>
    abstract fetchParticipantRooms(): Observable<IRoom[]>
    abstract deleteTeamRecentInteraction(key: string, id: string): void
    abstract updateInteractionStatus(interaction_id: number, status: InteractionStatus): void
    abstract deleteTeamThreadInteractions(payload: Object): void
    abstract getYobiOneTeamMessages(): Observable<RecentInteraction | undefined>
    abstract getInteractionTranscription(interaction_id: number): Observable<InteractionTranscription>
}
