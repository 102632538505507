<div class="mat-dialog-header border-b">
  <h2 class="text-xl font-bold text-dark">
    {{ (isUpdate ? localization.labels.update_user_information: localization.labels.add_a_new_user_to_your_team) |
    translate }}
  </h2>
  <svg-icon
    class="text-pale hover:text-warning cursor-pointer ml-auto"
    [svgStyle]="{ 'height.px': 24, 'width.px': 24 }"
    src="assets/icons/new-set/close.svg"
    (click)="close()"
  >
  </svg-icon>
</div>

<div class="mat-dialog-body text-dark">
  <ng-container *ngIf="userForm; else loadingRef">
    <form
      *ngIf="userForm"
      class="flex flex-col gap-4"
      [formGroup]="userForm"
    >
      <div
        *ngIf="isUpdate"
        class="flex gap-2 xs:flex-col"
      >
        <!-- ----------------------------- Given name ------------------------------ -->
        <app-input-wrapper
          class="flex-1"
          [label]="localization.labels.first_name | translate"
          [invalid]="userForm.get('firstname')?.invalid && userForm.get('firstname')?.touched"
        >
          <input
            input
            type="text"
            cdkFocusInitial
            autocomplete="off"
            [placeholder]="localization.labels.first_name | translate "
            formControlName="firstname"
          >
        </app-input-wrapper>

        <!-- ----------------------------- Family name ------------------------------ -->
        <app-input-wrapper
          class="flex-1"
          [label]="localization.labels.last_name | translate"
          [invalid]="userForm.get('lastname')?.invalid && userForm.get('lastname')?.touched"
        >
          <input
            input
            type="text"
            autocomplete="off"
            [placeholder]="localization.labels.last_name | translate "
            formControlName="lastname"
          >
        </app-input-wrapper>
      </div>

      <div
        *ngIf="!isUpdate"
        class="flex flex-col gap-2"
      >
        <!-- -------------------------------- email -------------------------------- -->
        <app-input-wrapper
          class="flex-1"
          [label]="localization.terms.email | translate"
          [invalid]="userForm.get('email')?.invalid && userForm.get('email')?.touched"
        >
          <input
            input
            type="text"
            autocomplete="off"
            [placeholder]="localization.placeholder.user_email_here | translate "
            formControlName="email"
          >
        </app-input-wrapper>
        <mat-error *ngIf="userForm.get('email')?.invalid && userForm.get('email')?.touched">
          {{localization.errors.invalid_email | translate}}.
        </mat-error>
      </div>

      <div class="flex flex-col gap-2">
        <!-- -------------------------------- Role --------------------------------- -->
        <app-input-wrapper
          class="flex-1"
          [label]="localization.labels.select_role | translate"
          [invalid]="userForm.get('role')?.invalid && userForm.get('role')?.touched"
        >
          <mat-select
            select
            formControlName="role"
            [disableOptionCentering]="true"
          >
            <mat-option [value]="roles.admin">
              {{ roles.admin | titlecase }}
            </mat-option>
            <mat-option [value]="roles.user">
              {{ roles.user | titlecase }}
            </mat-option>
          </mat-select>
        </app-input-wrapper>
      </div>
      <!-- AI PERMISSION -->
      <!-- && data?.user !== undefined -->
      <div 
        *ngIf="(isAdmin$ | async)"
        class="flex flex-col gap-2">
        <div class="flex flex-col gap-1">
            <label class="font-semibold text-mild">
                User AI Permissions
            </label>
            <div class="border-2 flex flex-col gap-3 p-4 py-3 rounded">
                <ng-container
                    *ngFor="let aiPermission of aiPermissionsOpt; index as i;">
                    <div class="flex flex-row items-center gap-2">
                        <svg-icon
                            class="text-primary"
                            *ngIf="aiPermission.model === 'create_synth_agent'"
                            [svgStyle]="{'height.px': 19, 'width.px': 19}"
                            [src]="'assets/icons/new-set/circle-plus.svg'">
                        </svg-icon>
                        <svg-icon
                            class="text-primary"
                            *ngIf="aiPermission.model === 'test_synth_agent'"
                            [svgStyle]="{'height.px': 19, 'width.px': 19}"
                            [src]="'assets/icons/new-set/testing-ai.svg'">
                        </svg-icon>
                        <svg-icon
                            class="text-primary"
                            *ngIf="aiPermission.model === 'train_synth_agent'"
                            [svgStyle]="{'height.px': 19, 'width.px': 19}"
                            [src]="'assets/icons/new-set/training-ai.svg'">
                        </svg-icon>
                        <svg-icon
                            class="text-primary"
                            *ngIf="aiPermission.model === 'configure_synth_agent'"
                            [svgStyle]="{'height.px': 19, 'width.px': 19}"
                            [src]="'assets/icons/new-set/settings.svg'">
                        </svg-icon>
                        <mat-checkbox
                            (change)="handleUserPermission($event, i)"
                            [checked]="aiPermission.value">
                            <span class="text-dark">{{aiPermission.name}}</span>
                      </mat-checkbox>
                    </div>

                </ng-container>
            </div>
        </div>
      </div>
      <div
        *ngIf="channels.controls.length"
        class="flex flex-col gap-2"
      >
        <!-- ------------------------------ Channels ------------------------------- -->
        <div
          class="flex flex-col gap-1"
          formArrayName="channels"
        >
          <label class="font-semibold text-mild">User Inbox Permissions</label>
          <div
            [ngClass]="userForm.get('channels')?.invalid && userForm.get('channels')?.touched ? 'border-warning' : 'secondary-border-color'"
            class="border-2 flex flex-col gap-3 p-4 py-3 rounded"
          >
            <ng-container *ngFor="let channel of channels.controls; index as i">
              <div class="flex text-base">
                <span class="text-lg font-bold">{{ channel.get('channel_label')!.value }}</span>
              </div>
              <div
                class="flex items-center gap-4"
                [formGroupName]="i"
              >
                <div class="flex items-center gap-2 w-48">
                  <app-channel-icon
                    [channel]="channel.get('channel_type')!.value"
                    [size]="1.1"
                  ></app-channel-icon>
                  <span class="truncate">{{ channel.get('channel_name')!.value | phone }}</span>
                </div>
                <div class="flex items-center gap-4">
                  <mat-checkbox
                    (change)="!$event.checked && channel.get('send')!.patchValue(false)"
                    formControlName="access"
                  >
                    <span class="text-dark">Receive</span>
                  </mat-checkbox>
                  <mat-checkbox
                    (change)="$event.checked && channel.get('access')!.patchValue(true)"
                    formControlName="send"
                  >
                    <span class="text-dark">Send</span>
                  </mat-checkbox>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
        <mat-error *ngIf="userForm.get('channels')?.invalid && userForm.get('channels')?.touched">
          {{ 'Add at least one channel' | translate }}.
        </mat-error>
      </div>
    </form>
  </ng-container>
</div>

<div class="mat-dialog-footer border-t">
  <div></div>
  <app-button
    color="primary"
    [disabled]="!userForm"
    (click)="onSubmit()"
  >
    <div class="px-6 flex items-center gap-2">
      {{ (isUpdate ? localization.labels.update : localization.labels.add) | translate }}
      <mat-spinner
        diameter="18"
        class="svg-stroke-white"
        *ngIf="creatingUser$ | async"
      > </mat-spinner>
    </div>
  </app-button>
</div>


<ng-template #loadingRef>
  <div class="flex flex-col items-center gap-3">
    <mat-spinner diameter="20"></mat-spinner>
    <span class="text-base font-semibold text-pale">
      Loading user channels...
    </span>
  </div>
</ng-template>
