import { CreateUserDto, CreateUserResponseDto, InviteUserResponseDto } from './../DTO/CreateUserDto';
import { environment } from '@/environments/environment';
import { Inject, Injectable } from '@angular/core';
import {
  map,
  NEVER,
  Observable,
  of,
  pluck,
  switchMap,
  tap,
  firstValueFrom,
  from,
} from 'rxjs';
import { DatabaseService } from '../services/Database/database.service';
import { SuccessApiResponse } from '../services/Networking/ApiResponse';
import { HttpRequestMethod } from '../services/Networking/HttpRequestMethod';
import { HttpService } from '../services/Networking/HttpService';
import { liveQuery } from 'dexie';
import { IUsersRepository } from '@/app/core/IRepositories/IUsersRepository';
import { User, UserInvitation } from '@/app/core/Models/User';
import { TeamMember } from '@/app/core/Models/TeamMember';
import { InviteUserDto } from '../DTO/CreateUserDto';

@Injectable({
  providedIn: 'root',
})
export class UsersRepository implements IUsersRepository {
  constructor(
    private httpService: HttpService,
    private databaseService: DatabaseService
  ) { }

  inviteUser(request: InviteUserDto): Observable<InviteUserResponseDto> {
    const requestURL = `${environment.apiURL}invite-user`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      request,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<InviteUserResponseDto>;
        return res.results;
      })
    );
  }

  createUser(request: CreateUserDto): Observable<CreateUserResponseDto> {
    const requestURL = `${environment.apiURL}create-user`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      request,
      false
    );
    return this.httpService.execute(options).pipe(
      map((item) => {
        return (item as SuccessApiResponse<CreateUserResponseDto>)
          .results!;
      })
    );
  }

  getUsers(): Observable<User[]> {
    // const requestURL = `${environment.apiURL}users-v2`;
    const requestURL = `${environment.apiURL}users-v3`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<User[]>;
        return res.results;
      })
    );
  }

  getInvitations(): Observable<UserInvitation[]> {
    const requestURL = `${environment.apiURL}invitations`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<UserInvitation[]>;
        return res.results;
      })
    );
  }

  deleteInvitation(invitationId: number): Observable<SuccessApiResponse<any>> {
    const requestURL = `${environment.apiURL}invitations/${invitationId}`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.delete,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<any>;
        return res;
      })
    );
  }

  saveUsersList(users: User[]): void {
    this.databaseService.transaction(
      'rw!',
      this.databaseService.users,
      async () => {
        const newUsersId = users.map((item) => item.user_id);
        const usersList = await this.databaseService.users.toArray();
        const removedUsersId = usersList
          .filter((item) => !newUsersId.includes(item.user_id))
          .map((item) => item.user_id as unknown as string);
        await this.databaseService.users.bulkDelete(removedUsersId);
        await this.databaseService.users.bulkPut(users);
      }
    );
  }

  getLocalUsersList(): Observable<User[]> {
    return from(
      liveQuery(() => {
        this.databaseService.users.mapToClass(User);
        return this.databaseService.users.toArray();
      })
    );
  }

  getLocalTeamMembers(): Observable<TeamMember[]> {
    return from(
      liveQuery(() => {
        this.databaseService.teamMembers.mapToClass(TeamMember);
        return this.databaseService.teamMembers.toArray();
      })
    );
  }

  getTeamMembers(): Observable<TeamMember[]> {
    const requestURL = `${environment.apiURL}teammates`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<TeamMember[]>;
        return res.results;
      })
    );
  }

  saveTeamMembers(members: TeamMember[]): void {
    this.databaseService.teamMembers.clear().then(() => {
      this.databaseService.teamMembers.bulkPut(members);
    });
  }
}
