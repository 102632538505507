import { DeleteUserDto } from './../DTO/DeleteUserDto';
import { UserReinviteDto } from './../DTO/UserInviteDto';
import { environment } from '@/environments/environment';
import { Inject, Injectable } from '@angular/core';
import { from, map, NEVER, Observable, of, pluck, switchMap, tap } from 'rxjs';
import { DatabaseService } from '../services/Database/database.service';
import { SuccessApiResponse } from '../services/Networking/ApiResponse';
import { HttpRequestMethod } from '../services/Networking/HttpRequestMethod';
import { HttpService } from '../services/Networking/HttpService';
import { EditUserDto, EditUserPermissionDto, EditUserResponseDto } from '../DTO/CreateUserDto';
import { IUserRepository } from '@/app/core/IRepositories/IUserRepository';
import { SuccessResponse } from '../DTO/successResponse';

@Injectable({
  providedIn: 'root',
})
export class UserRepository implements IUserRepository {
  constructor(
    private httpService: HttpService,
    private databaseService: DatabaseService
  ) { }

  deleteUser(request: DeleteUserDto): Observable<SuccessResponse> {
    const requestURL = `${environment.apiURL}users/${request.user_id}`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.delete,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<SuccessResponse>;
        return res.results;
      })
    );
  }

  deleteLocalUser(user_id: string): void {
    this.databaseService.teamMembers.delete(user_id)
  }

  editUser(request: EditUserDto, userId: number): Observable<EditUserResponseDto> {
    const requestURL = `${environment.apiURL}update-user/${userId}`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.put,
      this.httpService.createHeader(),
      requestURL,
      request,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<EditUserResponseDto>;
        return res.results;
      })
    );
  }

  reinviteUser(request: UserReinviteDto): Observable<SuccessResponse> {
    const requestURL = `${environment.apiURL}reinvite-user`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      request,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<SuccessResponse>;
        return res.results;
      })
    );
  }

  editUserAIPermission(request: EditUserPermissionDto): Observable<SuccessResponse> {
    const requestURL = `${environment.apiURL}users/${request.user_id}/permissions/${request.permission_name}`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.put,
      this.httpService.createHeader(),
      requestURL,
      { permission_value: request.permission_value },
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<SuccessResponse>;
        return res.results;
      })
    );
  }

}
