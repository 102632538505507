<div
  class="flex w-full flex-row h-full"
  cdkTrapFocus
  [cdkTrapFocusAutoCapture]="true"
>
  <app-auth-banner class="absolute flex-1 w-full h-full">
    <!-- <div class="flex flex-col gap-4 text-white">
      <div class="text-4xl font-semibold">
        <!-- {{ localization.auth.sign_up_title | translate }}
        Meet your AI Teammate
      </div>
      <div class="text-lg">
        <!-- {{ localization.auth.sign_up_slogan | translate }}
        Have a short 2 minute call with our Synthetic Agent!
      </div>
    </div> -->
  </app-auth-banner>

  <div class="wrapper">
    <div class="flex flex-col w-full max-w-xl sm:w-full gap-7 justify-center m-auto">
      <!-- <div class="flex flex-col gap-4 mb-6">
        <div class="text-3xl font-semibold text-center">
          {{ localization.labels.create_account | translate }}
        </div>
        <div class="text-md">
          {{ localization.auth.sign_up_slogan | translate }}
        </div>
      </div> -->
      <div class="text-3xl font-semibold text-center text-primary mb-6">
        Sign Up for Yobi
      </div>

      <!-- <div class="flex flex-row justify-center gap-10">
        <div
          (click)="googleLogin()"
          class="shadow hover:shadow-md cursor-pointer p-3 rounded-md flex items-center gap-2 flex-grow justify-center max-w-xs"
        >
          <img
            class="w-5 h-5"
            src="assets/icons/social/google.svg"
          />
          <span class="text-base text-zinc-600 sm:hidden xs:hidden">Google</span>
        </div>

        <div
          (click)="appleLogin()"
          class="shadow hover:shadow-md cursor-pointer p-3 rounded-md flex items-center gap-2 flex-grow justify-center max-w-xs"
        >
          <img
            class="w-5 h-5"
            src="assets/icons/social/apple.svg"
          />
          <span class="text-base text-zinc-600 sm:hidden xs:hidden">Apple</span>
        </div>

        <div
          (click)="facebookLogin()"
          class="shadow hover:shadow-md cursor-pointer p-3 rounded-md flex items-center gap-2 flex-grow justify-center max-w-xs"
        >
          <img
            class="w-5 h-5"
            src="assets/icons/social/facebook.svg"
          />
          <span class="text-base text-zinc-600 sm:hidden xs:hidden">Facebook</span>
        </div>
      </div> -->

      <!-- <span class="text-center text-gray-500 p-4 pt-6 text-base">
        {{ localization.auth.sign_up_use_social | translate }}
      </span> -->

      <form
        id="signup_form_id"
        class="flex flex-col gap-7"
        [formGroup]="infomationForm"
        (ngSubmit)="onFormSubmit()"
      >
        <!-- ---------------------------- business name ---------------------------- -->
        <!-- <div>
          <app-text-input
            [label]="localization.labels.business_name | translate"
            (ngModelChange)="businessNameChanged($event)"
            formControlName="business_name"
            [placeholder]="localization.placeholder.type_your_business_name | translate"
            [invalid]="infomationForm.get('business_name')!.touched && infomationForm.get('business_name')!.invalid"
          ></app-text-input>
          <app-error-text
            class="mt-1"
            *ngIf="infomationForm.get('business_name')?.getError('exists')"
          >
            {{ localization.errors.business_name_already_used | translate }}
          </app-error-text>
          <app-error-text
            class="mt-1"
            *ngIf="infomationForm.get('business_name')?.getError('required') && !infomationForm.get('business_name')?.pristine"
          >
            {{ localization.errors.business_name_required | translate }}
          </app-error-text>
        </div> -->

        <!-- ------------------------------ full name ------------------------------ -->
        <div *ngIf="infomationForm.get('full_name') as fullNameControl">
          <app-text-input
            [label]="'Full Name'"
            formControlName="full_name"
            placeholder="Enter your full name"
            [invalid]="fullNameControl.touched && fullNameControl.invalid"
          ></app-text-input>
          <app-error-text
            class="mt-1"
            *ngIf="fullNameControl.getError('required') && !fullNameControl.pristine"
          >
            Full name is required
          </app-error-text>
        </div>

        <!-- --------------------------- business email ---------------------------- -->
        <div class="min-h-[110px]" *ngIf="infomationForm.get('channel_value') as channelValueControl">
          <app-text-input
            #email
            [label]="localization.terms.email | translate"
            (ngModelChange)="emailChanged($event)"
            formControlName="channel_value"
            [placeholder]="localization.placeholder.type_your_email | translate"
            [invalid]="channelValueControl.touched && channelValueControl.invalid"
          ></app-text-input>
          <div class="h-[40px] pt-1">
            <app-error-text
              *ngIf="channelValueControl.getError('exists')"
            >
              {{ localization.errors.email_already_used | translate}}
            </app-error-text>
            <app-error-text
              *ngIf="channelValueControl.getError('required') && !channelValueControl.pristine"
            >
              {{ localization.errors.email_required | translate}}
            </app-error-text>
            <app-error-text
              *ngIf="channelValueControl.getError('email') && channelValueControl.dirty"
            >
              {{ localization.errors.email_not_valid | translate }}
            </app-error-text>
          </div>
        </div>

        <!-- ------------------------------ password ------------------------------- -->
        <div
          class="min-h-[110px]"
          *ngIf="infomationForm.get('password') as passwordControl"
        >
          <div class="flex flex-col gap-3 text-zinc-800">
            <app-password-input
              [label]="localization.placeholder.create_your_password | translate"
              formControlName="password"
              [invalid]="passwordControl.touched && passwordControl.invalid"
            ></app-password-input>
            <div class="h-[40px]">
              <app-error-text
                *ngIf="passwordControl.invalid && passwordControl.touched"
              >
                Password must be at least 8 characters long
              </app-error-text>
            </div>
          </div>
        </div>

        <!-- ----------------------------- invite code ----------------------------- -->
        <div class="min-h-[80px]">
          <app-text-input
            label="Private Invite Code (Optional)"
            formControlName="invite_code"
            placeholder="Enter code"
          ></app-text-input>
        </div>

        <!-- ---------------------------- submit button ---------------------------- -->
        <app-button
          [ngClass]="{
            'transition-all duration-300 bg-gray-300 text-gray-600': !infomationForm.valid,
            'animate-gradient-shine bg-gradient-to-r from-purple-600 to-indigo-600 text-white': infomationForm.valid && infomationForm.dirty
          }"
          class="w-full"
          type="submit"
          [disabled]="!infomationForm.valid"
        >
          {{ localization.auth.agree_and_sign_up | translate }}
        </app-button>
      </form>

      <!-- Add the full-page loading overlay -->
      <div class="transition-overlay" *ngIf="(singUpState$ | async)?.loading">
        <div class="fixed inset-0 bg-background z-40 backdrop-blur-md"></div>
        <div class="fixed inset-0 z-50 flex items-center justify-center">
          <div class="flex flex-col items-center gap-8">
            <div class="relative w-24 h-24">
              <div class="absolute inset-0 border-t-4 border-primary rounded-full animate-spin"></div>
              <div class="absolute inset-2 border-r-4 border-primary-light rounded-full animate-spin-reverse"></div>
              <div class="absolute inset-4 border-b-4 border-primary-lighter rounded-full animate-pulse"></div>
            </div>
            <div class="text-xl font-medium text-center text-strong">
              Creating your account...
            </div>
          </div>
        </div>
      </div>

      <div class="text-center text-lg px-4">
        <ng-container *ngFor="let item of localization.auth.sign_up_tos  | translate | translateCut; let i = index">
          <ng-container [ngSwitch]="item.type">
            <span *ngSwitchCase="'text'">
              {{ item.content }}
            </span>
            <span
              *ngSwitchCase="'section'"
              class="text-primary font-semibold cursor-pointer"
              (click)="i == 1 ? openTermsAndConditions() : openPrivacyPolicy()"
            >
              {{ item.content }}
            </span>
          </ng-container>
        </ng-container>
      </div>
      <div class="flex gap-3 mt-6 justify-center">
        {{ localization.auth.have_account | translate }}
        <span
          class="cursor-pointer text-primary font-semibold"
          routerLink="/auth/login"
        >
          {{ localization.auth.sign_in | translate }}
        </span>
      </div>
    </div>
  </div>
</div>
