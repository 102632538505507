import { Observable, Observer } from 'rxjs';
import { SuccessResponse } from '@/app/Data/DTO/successResponse';
import { ChannelBlockDto } from '@/app/Data/DTO/ChannelDto';
import {
  InteractionStatus,
  InteractionThread,
} from '@/app/core/Models/Interaction';
import { TaskStatus } from '../Models/Task';
import { InteractionClassificationQueryDto, SynthInteractionFeadbackDto } from '@/app/Data/DTO/InteractionDto';
import { InteractionClassification } from '../Models/interactionClassification';
import { AddInteractionComment } from '@/app/Data/DTO/Interaction/addInteractionCommentDto';
import { InteractionComment } from '../Models/Interaction/InteractionComment';
import { InteractionPinDto } from '@/app/Data/DTO/InteractionPinDto';

export abstract class IInteractionRepository {
  abstract archiveInteraction(channelId: number): Observable<SuccessResponse>;

  abstract unArchiveInteraction(channelId: number): Observable<SuccessResponse>;

  abstract pinInteraction(payload: InteractionPinDto): Observable<SuccessResponse>;

  abstract unPinInteraction(payload: InteractionPinDto): Observable<SuccessResponse>;

  abstract updateStatus(
    interactionsIds: number[],
    status: InteractionStatus
  ): Observable<SuccessResponse>;

  abstract updateLocalInteractionsStatus(
    interactionsIds: number[],
    status: InteractionStatus
  ): void;

  abstract updateLocalTaskInteractionStatus(
    interactionsId: number,
    status: TaskStatus
  ): void;

  abstract sendSynthInteractionFeedback(
    request: SynthInteractionFeadbackDto
  ): Observable<SuccessResponse<string>>


  abstract addInteractionComment(
    request: AddInteractionComment
  ): Observable<InteractionComment>

  abstract getInteractionComments(
    interactionId: string|number
  ): Observable<InteractionComment[]>

  abstract deleteComment(
    commentId: string|number
  ): Observable<InteractionComment>

  abstract updateComment(
    commentId: string|number,
    content: string
  ): Observable<InteractionComment>


  abstract archiveLocalInteraction(channelId: number): void;

  abstract unArchiveLocalInteraction(channelId: number): void;

  abstract pinLocalInteraction(channelId: number): void;

  abstract unPinLocalInteraction(channelId: number): void;

  abstract deleteThreadInteraction(
    interaction_id: number
  ): Observable<SuccessResponse<string>>;

  abstract deleteCallRecording(
    interaction_id: number
  ): Observable<SuccessResponse<string>>;

  abstract downloadCallRecording(
    interaction_id: number,
    progress$?: Observer<any>
  ): Observable<string>

  abstract downloadRecording(
    url: string,
    fileName: string
  ): void

  abstract getLocalThreadInteraction(interaction_id: string | number): Observable<InteractionThread | undefined>;

  abstract updateLocalThreadInteraction(interaction: InteractionThread): void;

  abstract getInteractionClassification(data: InteractionClassificationQueryDto): Observable<InteractionClassification>

  abstract saveInteractionClassification(interaction: InteractionClassification): void

  abstract getLocalInteractionClassification(): Observable<InteractionClassification[]>;

  abstract getLocalInteractionClassificationById(interaction_id: number): Observable<InteractionClassification | undefined>;

}
