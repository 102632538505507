import { from, map, Observable, pluck } from 'rxjs';
import { HttpService } from '@/app/Data/services/Networking/HttpService';
import { environment } from '@/environments/environment';
import { HttpRequestMethod } from '@/app/Data/services/Networking/HttpRequestMethod';
import { SuccessApiResponse } from '@/app/Data/services/Networking/ApiResponse';
import { Injectable } from '@angular/core';
import { ITasksRepository } from '@/app/core/IRepositories/ITasksRepository';
import { Task, TaskFilter, TaskStatus } from '@/app/core/Models/Task';
import { DatabaseService } from '@/app/Data/services/Database/database.service';
import { liveQuery } from 'dexie';

@Injectable()
export class TasksRepository extends ITasksRepository {
  constructor(
    private httpService: HttpService,
    private databaseService: DatabaseService
  ) {
    super();
  }

  getTasks(filter: TaskFilter, status: TaskStatus): Observable<Task[]> {
    const requestURL = `${environment.apiURL}tasks-v3/${filter}/status/${status}`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<Task[]>;
        return res.results;
      })
    );
  }

  saveTask(request: Task): void {
    this.databaseService.tasksList.put(request);
  }

  saveTasks(tasks: Task[]): void {
    this.databaseService
      .transaction('rw', [this.databaseService.tasksList], async () => {
        const localTasks = await this.databaseService.tasksList.toArray();
        const newTasksKeys = tasks.map((item) => item.task_id);
        const deletedTasks = localTasks.filter(({ task_id }) => newTasksKeys.indexOf(task_id) == -1)
        if (deletedTasks.length) {
          await this.databaseService.tasksList.bulkDelete(deletedTasks.map((item) => item.task_id as unknown as string));
        }
        await this.databaseService.tasksList.bulkPut(tasks);
      })
      .catch((error) => {
        console.error('Transaction Failed: ', error);
      });
  }

  getLocalTasks(filter: TaskFilter): Observable<Task[]> {
    return from(
      liveQuery(() => {
        this.databaseService.tasksList.mapToClass(Task);
        return this.databaseService.tasksList
          .filter((item) => filter == TaskFilter.Mine ? item.filter == filter : true)
          .sortBy('created_dt')
      })
    );
  }

  getTasksByChannelId(
    filter: TaskFilter,
    status: TaskStatus,
    channelId: number
  ): Observable<Task[]> {
    const requestURL = `${environment.apiURL}tasks/${filter}/status/${status}/channels/${channelId}`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<Task[]>;
        return res.results;
      })
    );
  }

  getLocalTaskById(taskId: number): Observable<Task | undefined> {
    return from(
      liveQuery(() => {
        this.databaseService.tasks.mapToClass(Task);
        return this.databaseService.tasksList.get(taskId as unknown as string);
      })
    );
  }

}
