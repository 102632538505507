import { OnboardingQuestionAnswerBody } from '@/app/Data/DTO/OnboardingDto';
import { BusinessInfoDto, BusinessNameFieldDto } from '@/app/Data/DTO/authDto';
import { ErrorApiReponse } from '@/app/Data/services/Networking/ApiResponse';
import { AppState } from '@/app/State/AppState';
import { selectTenantProfile } from '@/app/State/Tenant/tenant.selector';
import { selectCurrentPaymentMethod } from '@/app/State/billing/billing.selector';
import { LoadOnboardingQuestionsAction, SubmitOnboardingQuestionsAction } from '@/app/State/onboarding/onboarding.action';
import { selectOnboardingQuestionnaireState, selectVoiceSynthOnboardingState } from '@/app/State/onboarding/onboarding.selector';
import { selectPhoneVerificationRequestState } from '@/app/State/phoneRequest/phoneRequest.selector';
import { Localizations } from '@/app/Utilities/localization';
import { IOnboardingRepository } from '@/app/core/IRepositories/IOnboardingRepository';
import { ITenantRepository } from '@/app/core/IRepositories/ITenantRepository';
import { ChannelType } from '@/app/core/Models/ChannelTypes';
import { Roles } from '@/app/core/Models/Roles';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject, catchError, combineLatest, debounceTime, distinctUntilChanged, first, map, of, shareReplay, switchMap, tap } from 'rxjs';
import { GetTenantProfileAction } from './../../State/Tenant/tenant.action';
import { SnackbarService } from './../../Utilities/snackbar/snackbar.service';

export enum OnboardingStep {
  welcomeStep = 1,
  businessInfos = 2,
  nameStep = 3,
  roleStep = 4,
  greetingStep = 5,
  shareStep = 6,
  conversationStep = 7,
  tasksStep = 8,
  trainYourTeammateStep = 9,
  downloadQrStep = 10,
  completed = 11,
}

export enum OnboardingCallState {
  Disconnected = 'disconnected',
  Connecting = 'connecting',
  Connected = 'connected'
}

interface AgentState {
  agentId: string;
  state: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class OnboardingViewModel {
  profile$ = this.store.select(selectTenantProfile);
  private currentStep$ = new BehaviorSubject<OnboardingStep>(OnboardingStep.welcomeStep); // change it to welcome to see the whole onboarding
  private interactionData = new BehaviorSubject<any>(null);
  interactionData$ = this.interactionData.asObservable();
  private taskData = new BehaviorSubject<any>(null);
  taskData$ = this.taskData.asObservable();

  /* --------------------------- tenant informations -------------------------- */
  fullName$ = this.profile$.pipe(
    map(p => `${p?.firstName ?? ''} ${p?.lastName ?? ''}`),
    shareReplay({ bufferSize: 1, refCount: true })
  )
  private updatingBusinessName$ = new BehaviorSubject<boolean>(false);
  loading$ = this.updatingBusinessName$.asObservable();
  businessName$ = new Subject<string>()
  checkingBusinessNameExistance$ = new Subject<boolean>()
  businessNameExists$ = this.businessName$.pipe(
    debounceTime(200),
    tap(() => this.checkingBusinessNameExistance$.next(true)),
    switchMap((name) =>
      name?.length
        ? this.onboardingRepository
          .checkBusinessNameExisting(new BusinessNameFieldDto(name))
          .pipe(
            first(),
            map(() => false),
            catchError((er: ErrorApiReponse<any>) => of(true))
          )
        : of(false)
    ),
    tap(() => {
      this.checkingBusinessNameExistance$.next(false);
    })
  );

  private retellAgentSubjects = new Map<number, BehaviorSubject<AgentState>>();
  
  // Create observables map that components can subscribe to
  retellAgents: { [key: number]: Observable<AgentState> } = {};

  /* ------------------------------- onboarding ------------------------------- */
  verificationStep$ = this.currentStep$.asObservable();

  onboardingInteraction$ = this.onboardingRepository.getOnboardingInteraction().pipe(
    map(interaction => interaction.results)
  );

  private onboardingSynthName = new BehaviorSubject<string>('');
  onboardingSynthName$ = this.onboardingSynthName.asObservable();
  
  private onboardingUsername = new BehaviorSubject<string>('');
  onboardingUsername$ = this.onboardingUsername.asObservable();

  private onboardingShareToken = new BehaviorSubject<string>('');
  onboardingShareToken$ = this.onboardingShareToken.asObservable();

  private onboardingIndustry = new BehaviorSubject<string>('');
  onboardingIndustry$ = this.onboardingIndustry.asObservable();

  private onboardingWelcomeAgentState = new BehaviorSubject<boolean>(false);
  onboardingWelcomeAgentState$ = this.onboardingWelcomeAgentState.asObservable();

  private onboardingCallState = new BehaviorSubject<OnboardingCallState>(OnboardingCallState.Disconnected);
  onboardingCallState$ = this.onboardingCallState.asObservable();

  getOnboardingCallState(): OnboardingCallState {
    return this.onboardingCallState.getValue();
  }

  constructor(
    private store: Store<AppState>,
    private snackBarService: SnackbarService,
    private onboardingRepository: IOnboardingRepository,
    private tenantRepository: ITenantRepository,
    private router: Router
  ) {
    // Initialize the agents with their initial states
    const initialAgents: { [key: number]: AgentState } = {
      1: { agentId: 'agent_9ac7a0936e31c6973a9889faae', state: true },
      2: { agentId: 'agent_580fdf7146315192a0817cbcdd', state: true },
      3: { agentId: 'agent_fc38fba42486e056583332f404', state: true },
      4: { agentId: 'agent_fc38fba42486e056583332f404', state: true },
      5: { agentId: 'agent_fc38fba42486e056583332f404', state: true },
      6: { agentId: 'agent_97554c8c6ee1cd1415e2085b10', state: true },
      7: { agentId: 'agent_8152fb87a6aff21481e19f2982', state: true },
      8: { agentId: 'agent_3c8f5173187a59a7224a20e819', state: true },
      9: { agentId: 'agent_27d702548fc21d7bc7de1377b3', state: true },
      10: { agentId: 'agent_a8cab6f331b8c5655ea7b46782', state: true },
      11: { agentId: 'agent_baa81ed1c30cf6d56e74b9fbaf	', state: true }
    };
    // const initialAgents: { [key: number]: AgentState } = {
    //   1: { agentId: 'AAECAwQFBgcICQoLDA0OD2isT5jwI7YRfBZ-n0eE48jOailhGOfEct9Y2xjpDaF_X68DvoqTK3oM5wrB8Qxr', state: true },
    //   2: { agentId: 'AAECAwQFBgcICQoLDA0OD2isT5jwI7YRfBZ-n0eE48jOailhGOfEct9Y2xjpDaF_X68DvoqTK3oM5wrB8Qxr', state: true },
    //   3: { agentId: 'AAECAwQFBgcICQoLDA0OD2isT5jwI7YRfBZ-n0eE48jOailhGOfEct9Y2xjpDaF_X68DvoqTK3oM5wrB8Qxr', state: true },
    //   4: { agentId: 'AAECAwQFBgcICQoLDA0OD2isT5jwI7YRfBZ-n0eE48jOailhGOfEct9Y2xjpDaF_X68DvoqTK3oM5wrB8Qxr', state: true },
    //   5: { agentId: 'AAECAwQFBgcICQoLDA0OD2isT5jwI7YRfBZ-n0eE48jOailhGOfEct9Y2xjpDaF_X68DvoqTK3oM5wrB8Qxr', state: true },
    //   6: { agentId: 'AAECAwQFBgcICQoLDA0OD2isT5jwI7YRfBZ-n0eE48jOailhGOfEct9Y2xjpDaF_X68DvoqTK3oM5wrB8Qxr', state: true },
    //   7: { agentId: 'AAECAwQFBgcICQoLDA0OD2isT5jwI7YRfBZ-n0eE48jOailhGOfEct9Y2xjpDaF_X68DvoqTK3oM5wrB8Qxr', state: true },
    //   8: { agentId: 'AAECAwQFBgcICQoLDA0OD2isT5jwI7YRfBZ-n0eE48jOailhGOfEct9Y2xjpDaF_X68DvoqTK3oM5wrB8Qxr', state: true },
    //   9: { agentId: 'AAECAwQFBgcICQoLDA0OD2isT5jwI7YRfBZ-n0eE48jOailhGOfEct9Y2xjpDaF_X68DvoqTK3oM5wrB8Qxr', state: true },
    //   10: { agentId: 'AAECAwQFBgcICQoLDA0OD2isT5jwI7YRfBZ-n0eE48jOailhGOfEct9Y2xjpDaF_X68DvoqTK3oM5wrB8Qxr', state: true },
    //   11: { agentId: 'AAECAwQFBgcICQoLDA0OD2isT5jwI7YRfBZ-n0eE48jOailhGOfEct9Y2xjpDaF_X68DvoqTK3oM5wrB8Qxr', state: false }
    // };

    // Initialize BehaviorSubjects and Observables for each agent
    Object.entries(initialAgents).forEach(([step, agent]) => {
      const subject = new BehaviorSubject<AgentState>(agent);
      this.retellAgentSubjects.set(Number(step), subject);
      this.retellAgents[Number(step)] = subject.asObservable();
    });
  }

  // Method to update agent state
  updateAgentState(step: number, state: boolean): void {
    const subject = this.retellAgentSubjects.get(step);
    if (subject) {
      const currentValue = subject.value;
      subject.next({ ...currentValue, state });
    }
  }

  // Method to update both agentId and state
  updateAgent(step: number, agentId: string, state: boolean): void {
    const subject = this.retellAgentSubjects.get(step);
    if (subject) {
      subject.next({ agentId, state });
    }
  }

  updateBusinessAndFullName(data: BusinessInfoDto): void {
    this.updatingBusinessName$?.next(true);
    this.onboardingRepository
      .updateBusinessAndFullName(data)
      .pipe(
        tap({
          next: () => {
            this.updatingBusinessName$?.next(false);
            this.store.dispatch(GetTenantProfileAction());
            this.goToNextStep();
          },
          error: (err: ErrorApiReponse<any>) => {
            this.updatingBusinessName$?.next(false);
            this.snackBarService.openAlert({
              message: err.responseError?.message ?? Localizations.alert_dialog.something_went_wrong,
              type: 'failure',
              duration: 2000
            });
          }
        })
      )
      .subscribe();
  }

  goToNextStep() {
    const currentStep = this.currentStep$.value;
    if (currentStep < OnboardingStep.completed) {
      this.currentStep$.next(currentStep + 1);
    }
  }

  goToPreviousStep() {
    const currentStep = this.currentStep$.value;
    console.log('currentStep', currentStep);
    if (currentStep > OnboardingStep.businessInfos) {
      this.currentStep$.next(currentStep - 1);
    }
  }

  complete() {
    this.store.dispatch(GetTenantProfileAction())
    this.router.navigate(['/main/home'])
  }

  setInteractionData(data: any) {
    this.interactionData.next(data);
  }

  setTaskData(data: any) {
    console.log('Setting task data:', data);
    this.taskData.next(data);
  }

  setOnboardingSynthName(name: string) {
    this.onboardingSynthName.next(name);
  }

  setOnboardingUsername(name: string) {
    this.onboardingUsername.next(name);
  }

  setOnboardingIndustry(industry: string) {
    this.onboardingIndustry.next(industry);
  }

  setOnboardingShareToken(token: string) {
    this.onboardingShareToken.next(token);
  }

  setOnboardingWelcomeAgentState(state: boolean) {
    this.onboardingWelcomeAgentState.next(state);
  }

  setOnboardingCallState(state: OnboardingCallState) {
    this.onboardingCallState.next(state);
  }
}
