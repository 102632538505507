import { SuccessResponse } from '@/app/Data/DTO/successResponse';
import {  Observable} from 'rxjs';
import { OnboardingQuestionsSignature } from '../Models/Onboarding';
import { OnboardingQuestionAnswerBody } from '@/app/Data/DTO/OnboardingDto';
import { SuccessApiResponse } from '@/app/Data/services/Networking/ApiResponse';
import { BusinessInfoDto, BusinessNameFieldDto } from '@/app/Data/DTO/authDto';

export abstract class IOnboardingRepository {
  abstract updateBusinessAndFullName(request: BusinessInfoDto): Observable<SuccessResponse>;
  abstract checkBusinessNameExisting(request: BusinessNameFieldDto): Observable<SuccessResponse>;
  abstract getQuestions(): Observable<OnboardingQuestionsSignature[]>;
  abstract answerQuestion(request: OnboardingQuestionAnswerBody): Observable<SuccessApiResponse<any>>;
  abstract getOnboardingInteraction(): Observable<SuccessApiResponse<any>>;
}
