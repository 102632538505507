import { Injectable } from "@angular/core";
import { SwUpdate } from "@angular/service-worker";
import { Subject } from "rxjs";
import { ILocalStorageService } from "../Data/Adapters/ILocalStorageService";
import { APP_USER_CHOICE } from "./TokenKeys";
import { MixpanelService } from "../shared/analytics/mixpanel-service.service";
import { AnalyticsEventsNames } from "../shared/analytics/analyticsEvents";
import { environment } from "@/environments/environment";
import { DATABASE_NAME, DatabaseService } from "../Data/services/Database/database.service";
import { Router } from "@angular/router";

@Injectable({ providedIn: 'root' })
export class PWAService {
  private installPrompt: BeforeInstallPromptEvent;
  readonly isNewUpdate$ = new Subject()
  readonly isAppInstallable$ = new Subject()
  constructor(
    private updates: SwUpdate,
    private storageService: ILocalStorageService,
    private mixpanelService: MixpanelService,
    private databaseService: DatabaseService,
    private router: Router
  ) { }

  listenForUpdates(): void {
    this.updates.versionUpdates.subscribe(evt => {
      switch (evt.type) {
        case 'VERSION_DETECTED':
          //console.log(`Downloading new app version: ${evt.version.hash}`);
          break;
        case 'VERSION_READY':
          //console.log(`Current app version: ${evt.currentVersion.hash}`);
          //console.log(`New app version ready for use: ${evt.latestVersion.hash}`);
          this.isNewUpdate$.next(true)
          break;
        case 'VERSION_INSTALLATION_FAILED':
          //console.log(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
          break;
      }
    });
    this.updates.unrecoverable.subscribe(event => {
      console.log(`An error occurred that we cannot recover from:\n ${event.reason} \n\nPlease reload the page.`)
      this.isNewUpdate$.next(true)
    });
  }

  async forceUpdate() {
    // if ((environment.appVersion as string)
    //   .split('.')
    //   .map(Number)
    //   .reduce((acc, v, i) => acc + (3 - i) * (v || 1), 0) <= 24
    // ) { // 4.5.2 => 4x3 + 5x2 + 2x1 === 24
    //   console.log('clearing db');
    //   indexedDB.deleteDatabase(DATABASE_NAME)
    //   await this.databaseService.clear()
    // }
    document.location.reload();
  }

  getInstallPrompt() {
    window.addEventListener('beforeinstallprompt', (e) => {
      if ((this.storageService.getItem(APP_USER_CHOICE)) !== 'dismissed') {
        e.preventDefault();
        this.installPrompt = e as BeforeInstallPromptEvent
        setTimeout(() => {
          this.isAppInstallable$.next(true)
        }, 5000);
      }
    });
    window.addEventListener('appinstalled', (event) => {
      this.mixpanelService.track({ name: AnalyticsEventsNames.PWA_APP_INSTALLED })
      this.isAppInstallable$.next(false)
    });
  }

  askToInstallApp() {
    // this.installPrompt?.prompt()
    // this.isAppInstallable$.next(false);
    // this.installPrompt?.userChoice.then(res => {
    //   this.storageService.setItem(APP_USER_CHOICE, res.outcome)
    // })
    this.router.navigate(['main/settings/profile']);
    this.isAppInstallable$.next(false);
    this.storageService.setItem(APP_USER_CHOICE, 'accepted')
  }

  closeInstallApp() {
    this.isAppInstallable$.next(false);
    this.storageService.setItem(APP_USER_CHOICE, 'dismissed')
  }

  get isBadgeSupported() {
    return !!(navigator as any).setAppBadge
  }

  setBadge(contents?: number | undefined) {
    this.isBadgeSupported && (navigator as any).setAppBadge(Math.min(contents ?? 0, 99));
  }
  
  clearBadge() {
    this.isBadgeSupported && (navigator as any).clearAppBadge();
  }
}


interface BeforeInstallPromptEvent extends Event {

  /**
   * Returns an array of DOMString items containing the platforms on which the event was dispatched.
   * This is provided for user agents that want to present a choice of versions to the user such as,
   * for example, "web" or "play" which would allow the user to chose between a web version or
   * an Android version.
   */
  readonly platforms: Array<string>;

  /**
   * Returns a Promise that resolves to a DOMString containing either "accepted" or "dismissed".
   */
  readonly userChoice: Promise<{
    outcome: 'accepted' | 'dismissed',
    platform: string
  }>;

  /**
   * Allows a developer to show the install prompt at a time of their own choosing.
   * This method returns a Promise.
   */
  prompt(): Promise<void>;

}