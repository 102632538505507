import { Contact, UnclassifiedContact } from '@/app/core/Models/contact';
import { RecentInteractionsFilter } from '@/app/core/Models/RecentInteractionsFilter';
import { ChannelType } from './ChannelTypes';
import { Lang } from './lang';
import { Inbox } from './inbox';
import { TranscriptionDto } from '@/app/Data/DTO/TranscriptionDto';
import { environment } from "@/environments/environment";

export class InteractionTranscription {
  created_at: string
  interaction_id: any
  interaction_type: string
  transcription: any | Array<{ speaker: string, channel: string, start: number, text: string, transcription_id: number }>
}
export interface RecentMessagesQueryParams {
  page: number;
  page_size: number;
  type?: 'all' | 'groups' | 'personal'
}

export interface RecentMessagesQuery {
  params: RecentMessagesQueryParams
  filter: RecentInteractionsFilter;
  inbox?: Inbox
}

export type InlineNoteBody = (
  | { type: 'text'; text: string }
  | { type: 'mention'; user: string; id: number }
)[];

export type InteractionBody = {
  content_type: string;
  content: string;
  name?: string
  user?: {
    user_id: number;
    family_name: string;
    given_name: string;
    avatar: string | null;
  };
  deletedBy?: string;
  isDeleted?: string;
  'answered-by'?: string,
}[];

export type EmailBody = {
  type: | 'recipient_email' | 'sender_email' | 'subject' | 'body' | 'web_link',
  content: string,
}[];

export enum InteractionDirection {
  outbound = 'outbound',
  inbound = 'inbound',
}

export enum InteractionType {
  message = 'message',
  note = 'note',
  task = 'task',
  voice = 'voice',
  agent_call = 'agent_call'
}

export enum InteractionStatus {
  sent = 'sent',
  read = 'read',
  received = 'received',
  ringing = 'ringing',
  unread = 'unread',
  sending = 'sending',
  failed = 'failed',
  undelivered = 'undelivered',
}

export enum InteractionCategory {
  Active = 'active',
  Blocked = 'blocked',
  Archived = 'archived',
}

export enum VoicemailStatus {
  processing = 'processing',
  unhandled = 'unhandled',
  ongoing = 'ongoing',
  completed = 'completed',
}

export abstract class BaseInteraction {
  public created_dt: string;
  public direction: InteractionDirection;
  public interaction_dt: Date;
  public interaction_from: string;
  public interaction_id: number;
  public external_interaction_id: string;
  public interaction_to: string;
  public interaction_type: InteractionType;
  public status: InteractionStatus;
  public text_body: string;
  public updated_dt: string;
  public user_id: string;
  public vendor_name: string;
  public summarization?: string;
  public translation?: null | { // only thing we need from backend
    src_lang: Lang,
    target_lang: Lang,
    translated_msg: string
  } // update this in second action

  get body(): InteractionBody {
    let body: {
      content_type: string;
      content: string;
      user?: {
        user_id: number;
        family_name: string;
        given_name: string;
        avatar: string | null;
      };
      deletedBy?: string;
      isDeleted?: string;
    }[] = [];
    if (this.interaction_type === 'agent_call') {
      return body = [
        {
          content_type: 'agent_call',
          content: JSON.parse(this.text_body),
        },
      ];
    }
    try {
      body = JSON.parse(this.text_body);
      if (Array.isArray(body) && !body.length) {
        body = [{
          content_type: 'text',
          content: 'Message type not supported, please contact Yobi Customer Support for further assistance'
        }]
      }
      if (!Array.isArray(body)) {
        throw new Error("body detected as number");
      }
    } catch {
      body = [
        {
          content_type: 'text',
          content: this.text_body,
        },
      ];
    }
    return body;
  }

  get emailBody(): EmailBody {
    return this.body.find(x => x.content_type === 'email')?.content as unknown as EmailBody
  }
}

export class Interaction extends BaseInteraction { }

export type InteractionCommentItem = {
  avatar?: string,
  family_name?: string,
  given_name?: string,
  user_id: string
}
export class InteractionThread extends BaseInteraction {
  channel_id: number;
  channel_type: ChannelType;
  is_room?: boolean;
  page: number;
  channel_value: string;
  sender_name?: string | null;
  avatar?: string | null;
  yobi_contact_id?: number | null;
  yobi_tenant_id: number;
  msg_id?: string | number;
  extra_content: {
    recording_url: string,
    synth_name: string
  };
  inbox_id: number;
  is_synth?: boolean;
  comments_count: number
  comments_users?: InteractionCommentItem[]
  reactions?: {
    reaction: string,
    user_id: string | number,
    temp?: boolean
  }[]
  sender_id?: string | any
  receivers_status: {
    status: InteractionStatus,
    updated_dt: string,
    user_id: number | string
    name?: string,
    avatar?: string
  }[]
}

export class RecentInteraction {
  customId?: any;
  avatar: string;
  is_room?: boolean;
  room_id: string;
  room_name?: string;
  private?: boolean;
  msg_id?: number;
  channel_id: number;
  channel_type: ChannelType;
  channel_value: string;
  contact_id: number | null;
  id: number;
  inbox_id: number;
  email: string | null;
  family_name: string | null;
  given_name: string | null;
  sender: string;
  interaction: Interaction;
  page: number;
  is_archived: boolean;
  is_pinned: boolean;
  is_blocked: boolean;
  middle_name: string | null;
  preferred_name: string;
  tenant_id: number;
  unclassified_channel_id: number | null;
  yobi_contact_id: number | null;
  yobi_tenant_id: number;
  interaction_dt: Date;
  receivers_status: {
    status: InteractionStatus,
    updated_dt: string,
    user_id: number | string
  }[]
  metadata?: any
  get direction(): InteractionDirection {
    return this.interaction.direction;
  }

  get interactionType() {
    return this.interaction.interaction_type
  }
  get body(): {
    content_type: string;
    content: string;
    user?: {
      user_id: number;
      family_name: string;
      given_name: string;
      avatar: string | null;
    };
  }[] {
    let body: {
      content_type: string;
      content: string;
      user?: {
        user_id: number;
        family_name: string;
        given_name: string;
        avatar: string | null;
      };
    }[] = [];

    if (this.interactionType === 'agent_call') {
      return body = [
        {
          content_type: 'agent_call',
          content: this.interaction.text_body,
        }
      ];
    }
    try {
      //const text_body = this.interaction.text_body.replace(/None/gi, 'null');
      const text_body = this.interaction.text_body;
      if (!isNaN(text_body as any)) {
        throw "Not string structure"
      }
      body = JSON.parse(text_body); // to fix backend issue
    } catch {
      body = [
        {
          content_type: 'text',
          content: this.interaction.text_body,
        },
      ];
    }
    return body;
  }

  get is_contact(): boolean {
    return !!this.yobi_contact_id;
  }

  get displayName(): string {
    return this.is_contact ? `${this.given_name ?? ''} ${this.family_name ?? ''}` : [ChannelType.Facebook, ChannelType.Twitter, ChannelType.Instagram, ChannelType.Viber, ChannelType.Whatsapp, ChannelType.ChatWidget, ChannelType.WebCall].includes(this.channel_type)
      ? this.preferred_name ?? this.channel_value
      : this.channel_value
  }

  get mapToContact(): Contact {
    return Object.assign<Contact, Partial<Contact>>(new Contact(), {
      contact_id: this.yobi_contact_id!,
      avatar: this.avatar,
      tenant_id: this.tenant_id,
      family_name: this.family_name!,
      given_name: this.given_name!,
      email: this.email!,
      preferred_name: this.preferred_name!,
      middle_name: this.middle_name!,
      displayName: this.displayName,
    });
  }
  get mapToUnclassifiedContact(): UnclassifiedContact {
    return Object.assign<UnclassifiedContact, Partial<UnclassifiedContact>>(new UnclassifiedContact(), {
      unclassifiedChannelId: this.unclassified_channel_id,
      channelValue: this.channel_value,
      channelType: this.channel_type,
      channelId: this.channel_id,
      is_blocked: this.is_blocked,
      avatar: this.avatar,
      tenant_id: this.tenant_id,
      displayName: this.displayName,
    });
  }
  get mapToInteractionThread(): InteractionThread {
    return Object.assign<InteractionThread, Partial<InteractionThread>>(new InteractionThread(), {
      channel_id: this.channel_id,
      channel_type: this.channel_type,
      channel_value: this.channel_value,
      created_dt: this.interaction.created_dt,
      direction: this.interaction.direction,
      interaction_dt: this.interaction_dt,
      interaction_from: this.interaction.interaction_from,
      interaction_id: this.interaction.interaction_id,
      external_interaction_id: this.interaction.external_interaction_id,
      interaction_to: this.interaction.interaction_to,
      interaction_type: this.interaction.interaction_type,
      page: 1,
      sender_name: this.sender ?? '',
      status: this.interaction.status,
      text_body: this.interaction.text_body,
      updated_dt: this.interaction.updated_dt,
      user_id: this.interaction.user_id,
      vendor_name: this.interaction.vendor_name,
      yobi_contact_id: this.yobi_contact_id,
      yobi_tenant_id: this.yobi_tenant_id,
      translation: this.interaction.translation,
      summarization: this.interaction.summarization,
      receivers_status: this.receivers_status
    })
  }
  get mapToVoicemailInteraction(): VoicemailInteraction {
    return Object.assign<VoicemailInteraction, Partial<VoicemailInteraction>>(new VoicemailInteraction(), {
      external_interaction_id: this.interaction.external_interaction_id,
      interaction_id: this.interaction.interaction_id,
      user_id: this.interaction.user_id,
      tenant_id: this.tenant_id,
      channel_id: this.channel_id,
      contact_id: this.contact_id,
      unclassified_channel_id: this.unclassified_channel_id,

      interaction_dt: this.interaction_dt,
      interaction_from: this.interaction.interaction_from,
      interaction_to: this.interaction.interaction_to,
      interaction_type: 'voice',
      direction: this.interaction.direction,
      synth_voicemail_status: VoicemailStatus.ongoing, // TODO add corresponding field to interaction
      status: this.interaction.status,
      text_body: this.interaction.text_body,
      summarization: this.interaction.summarization,

      created_dt: this.interaction.created_dt,
      updated_dt: this.interaction.updated_dt,

      avatar: this.avatar,
      family_name: this.family_name,
      given_name: this.given_name,
      contact_info: this.displayName,
      page: 1
    })
  }
}
export class SocketInteraction extends BaseInteraction {
  channel_id: number;
  inbox_id: number;
  channel_type: ChannelType;
  sender: string;
  channel_value: string;
  contact_details: {
    avatar: string;
    channel_id: number;
    channel_name: string;
    contact_id: number;
    tenant_id?: number;
    family_name: string;
    given_name: string;
    is_contact: boolean;
    middle_name: string;
    preferred_name: string;
    unclassified_channel_id: number;
  };
  yobi_tenant_id: number;
  yobi_contact_id: number;
  msg_id?: number;
  is_synth?: boolean;
  silent: boolean
  synth_voicemail_status?: VoicemailStatus;
  get interactionParsedDate(): Date {
    return new Date(this.interaction_dt);
  }

  mapToRecentInteraction(): RecentInteraction {
    const newInteraction = Object.assign<Interaction, Partial<Interaction>>(new Interaction(), {
      created_dt: this.created_dt,
      direction: this.direction,
      interaction_dt: this.interactionParsedDate,
      interaction_from: this.interaction_from,
      interaction_id: this.interaction_id,
      external_interaction_id: this.external_interaction_id,
      interaction_to: this.interaction_to,
      interaction_type: this.interaction_type,
      status: this.status,
      text_body: this.text_body,
      user_id: this.user_id,
      vendor_name: this.vendor_name,
      translation: this.translation,
      summarization: this.summarization,
    });
    return Object.assign<RecentInteraction, Partial<RecentInteraction>>(new RecentInteraction(), {
      interaction: newInteraction,
      id: this.channel_id,
      msg_id: this.msg_id,
      avatar: this.contact_details?.avatar,
      channel_id: this.channel_id,
      channel_type: this.channel_type,
      inbox_id: this.inbox_id,
      channel_value: this.channel_value,
      contact_id: this.contact_details?.contact_id,
      family_name: this.contact_details?.family_name,
      given_name: this.contact_details?.given_name,
      middle_name: this.contact_details?.middle_name,
      preferred_name: this.contact_details?.preferred_name,
      tenant_id: this.yobi_tenant_id,
      unclassified_channel_id: this.contact_details?.unclassified_channel_id,
      yobi_contact_id: this.yobi_contact_id || this.contact_details.contact_id,
      interaction_dt: this.interactionParsedDate,
      page: 1,
    })
  }

  mapToThreadInteraction(): InteractionThread {
    return Object.assign<InteractionThread, Partial<InteractionThread>>(new InteractionThread(), {
      direction: this.direction,
      page: 1,
      interaction_dt: this.interactionParsedDate,
      interaction_from: this.interaction_from,
      interaction_id: this.interaction_id,
      external_interaction_id: this.external_interaction_id,
      interaction_to: this.interaction_to,
      interaction_type: this.interaction_type,
      status: this.status,
      avatar: this.direction == InteractionDirection.inbound && this.contact_details.avatar || this.body[0].user?.avatar,
      sender_name: this.sender ?? (this.body[0].user ? `${this.body[0].user?.given_name} ${this.body[0].user?.family_name}` : ''),
      text_body: this.text_body,
      updated_dt: this.updated_dt,
      user_id: this.user_id,
      vendor_name: this.vendor_name,
      channel_id: this.channel_id,
      channel_type: this.channel_type,
      created_dt: this.created_dt,
      channel_value: this.channel_value,
      yobi_contact_id: this.yobi_contact_id || this.contact_details.contact_id,
      yobi_tenant_id: this.yobi_tenant_id,
      translation: this.translation,
      summarization: this.summarization,
      is_synth: this.is_synth
    })
  }

  mapToVoivemailInteraction(): VoicemailInteraction {
    const voicemail = Object.assign<VoicemailInteraction, Partial<VoicemailInteraction>>(new VoicemailInteraction(), {
      external_interaction_id: this.external_interaction_id,
      interaction_id: this.interaction_id,
      user_id: this.user_id,
      tenant_id: this.yobi_tenant_id,
      channel_id: this.channel_id,
      contact_id: this.yobi_contact_id || this.contact_details.contact_id,
      unclassified_channel_id: this.contact_details.unclassified_channel_id,
      interaction_dt: this.interactionParsedDate,
      direction: this.direction,
      interaction_from: this.interaction_from,
      interaction_to: this.interaction_to,
      interaction_type: 'voice',
      synth_voicemail_status: this.synth_voicemail_status,
      status: this.status,
      text_body: this.text_body,
      summarization: this.summarization,
      created_dt: this.created_dt,
      updated_dt: this.updated_dt,
      avatar: this.contact_details.avatar,
      family_name: this.contact_details.family_name,
      given_name: this.contact_details.given_name,
      contact_info: `${this.contact_details.family_name} ${this.contact_details.given_name}`.trim() ?? this.interaction_from,
      page: 1
    });
    voicemail.extractData();
    return voicemail;
  }
}

export class VoicemailInteraction {
  external_interaction_id: string;
  interaction_id: string | number;
  user_id: string | number | null;
  tenant_id: string | number | null;
  channel_id: string | number;
  contact_id: string | number | null;
  unclassified_channel_id: string | number | null;

  interaction_dt: Date;
  interaction_from: string;
  interaction_to: string;
  interaction_type: 'voice';
  direction: InteractionDirection;
  synth_voicemail_status: VoicemailStatus;
  summarization?: string;
  status: InteractionStatus
  text_body: string

  created_dt: string;
  updated_dt: string;

  avatar: string;
  family_name: string | null;
  given_name: string | null;
  contact_info: string;

  page?: number
  data?: {
    audio?: string,
    transcription?: TranscriptionDto[],
    inlineTranscription?: string,
  }
  get displayName() {
    return this.contact_info && !this.contact_info.includes('null') ? this.contact_info
      : this.given_name || this.family_name ? `${this.given_name ?? ''} ${this.family_name ?? ''}`.trim()
        : this.interaction_from
  }

  get body(): Array<{ content_type: 'audio' | 'transcription', content: string }> {
    try {
      return JSON.parse(this.text_body)
    } catch {
      return []
    }
  }

  extractData() {
    try {
      const body = this.body;
      const transcription = JSON.parse(body.find(x => x.content_type === 'transcription')?.content ?? '[]') as TranscriptionDto[]
      this.data = {
        audio: body.find(x => x.content_type === 'audio')?.content?.replace(/^http:\/\//, (environment.production ? 'https://' : 'http://')),
        transcription,
        // inlineTranscription: transcription.map(({ text, speaker }) => (`<span>${speaker}: </span><span>${text.substring(0, 100)}</span>`)).slice(0, 2).join('<br>')
        inlineTranscription: [transcription[transcription.length - 1]]
          .map(({ text, speaker }) => (`${speaker}: ${text.substring(0, 50)}${text.length > 100 ? '...' : ''}`))
          .slice(0, 5)
          .join('\n')
      }
    } catch {
      this.data = {}
    }
  }
}
