import { Observable } from 'rxjs';
import { Task, TaskDetail, TaskFilter, TaskStatus } from '@/app/core/Models/Task';
import { PatchTaskDto } from '@/app/Data/DTO/TaskDto';

export abstract class ITasksRepository {
  abstract getTasks(filter: TaskFilter, status: TaskStatus): Observable<Task[]>;
  abstract getTasksByChannelId(
    filter: TaskFilter,
    status: TaskStatus,
    channelId: number
  ): Observable<Task[]>;
  abstract saveTasks(tasks: Task[]): void;
  abstract saveTask(request: Task): void;
  abstract getLocalTasks(filter: TaskFilter): Observable<Task[]>;
  abstract getLocalTaskById(taskId: number): Observable<Task | undefined>;

}
