<div class="img-uploader-content">
    <!-- <h4>{{title}}</h4> -->
    <div
        appFileUploader
        class="img-uploader-dropzone"
        [validFileExt]="validFileExt"
        (fileDropped)="onFileDropped($event)">

        <input
            #fileDropRef
            type="file"
            id="fileDropRef"
            multiple
            (click)="resetUploadedDocuments($event)"
            (change)="fileBrowseHandler($any($event.target).files)"/>

        <div class="img-uploader-label">
            <svg-icon
                src="assets/icons/new-set/cloud-upload.svg"
                [svgStyle]="{ 'height.px': 40, 'width.px': 40 }">
            </svg-icon>
             <span class="img-label">Drop or click to upload your files here.</span>
        </div>
    </div>
    <div class="img-desc">
        <p>{{description}}</p>
    </div>
    <div class="img-files">
        <div class="img-label-count" *ngIf="files?.length">
            <span [ngClass]="{'error': error}">{{files?.length}}/{{max}}</span>
        </div>
        <div
            *ngFor="let file of files; index as i;"
            class="file flex flex-row gap-4">
            <div class="icon">
                <svg-icon
                    *ngIf="file.type === 'application/pdf'"
                    class="cursor-pointer"
                    (click)="openFileInNewTab(file.url)"
                    src="assets/icons/new-set/file_pdf.svg"
                    [svgStyle]="{ 'height.px': 40, 'width.px': 40 }">
                </svg-icon>
                <!-- <svg-icon
                    *ngIf="file.type === 'image/svg+xml'"
                    class="cursor-pointer"
                    src="assets/icons/new-set/file_svg.svg"
                    [svgStyle]="{ 'height.px': 40, 'width.px': 40 }">
                </svg-icon> -->
                <svg-icon
                    *ngIf="file.type === 'text/plain'"
                    class="cursor-pointer"
                    (click)="openFileInNewTab(file.url)"
                    src="assets/icons/new-set/file_txt.svg"
                    [svgStyle]="{ 'height.px': 40, 'width.px': 40 }">
                </svg-icon>
                <svg-icon
                    *ngIf="file.type !== 'text/plain' && file.type !== 'application/pdf'"
                    class="cursor-pointer"
                    (click)="openFileInNewTab(file.url)"
                    src="assets/icons/new-set/default.svg"
                    [svgStyle]="{ 'height.px': 40, 'width.px': 40 }">
                </svg-icon>
            </div>
            <div class="info flex flex-col gap-1.5">
                <div class="flex flex-row justify-between">
                    <span class="text-sm font-semibold">{{ decodeFileName(file.name) }}</span>
                    <div class="flex items-center gap-4">
                        <svg-icon
                            (click)="deleteFile(i)"
                            [ngClass]="{
                                'pointer-events-none': file.progress !== 100,
                                'pointer-events-auto cursor-pointer': file.progress === 100
                            }"
                            src="assets/icons/new-set/trash.svg"
                            [svgStyle]="{ 'height.px': 20, 'width.px': 20 }">
                        </svg-icon>
                    </div>
                </div>
                <span class="text-sm font-normal">{{file.fileSize}}</span>
                <div
                    *ngIf="file.uploading"
                    class="flex flex-row justify-between gap-1.5 items-center">
                    <div class="progress-cont">
                        <div class="progress" [style.width]="file.progress + '%'"></div>
                    </div>
                    <span>{{file.progress}}%</span>
                </div>
            </div>
        </div>
    </div>
    <div
        *ngIf="error">
        <span class="text-warning font-semibold">{{errorMessage}}.</span>
    </div>
</div>
