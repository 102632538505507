import {
  InteractionStatus,
  InteractionThread,
  InteractionTranscription,
  RecentInteraction,
  RecentMessagesQuery,
  RecentMessagesQueryParams,
} from '@/app/core/Models/Interaction';
import { environment } from '@/environments/environment';
import { Injectable } from '@angular/core';
import { Observable, from, map, pluck, tap, last, Observer, BehaviorSubject } from 'rxjs';
import { DatabaseService } from '../services/Database/database.service';

import { ITeamInteractionRepository } from '@/app/core/IRepositories/ITeamInteractionRepository';
import { TeamInteraction } from '@/app/core/Models/interaction-team';
import { liveQuery } from 'dexie';
import { ICreateRoomResponse, InputCreateRoom, IParticipantResponse, IRoomAvatarResponseDto, IRoomResponse, TeamChatMessageDto } from '../DTO/TeamChatMessageDto';
import { TeamChatThreadMessagesQueryDto } from '../DTO/TeamChatThreadMessagesQueryDto';
import { SuccessApiResponse } from '../services/Networking/ApiResponse';
import { HttpRequestMethod } from '../services/Networking/HttpRequestMethod';
import { HttpService } from '../services/Networking/HttpService';
import { InternalMessageStatusDto } from '../DTO/InternalMessageStatusDto';
import { getUploadEventProgress } from '@/app/Utilities/functions/getUploadEventProgress';
import { SuccessResponse } from '../DTO/successResponse';
import { IRoom } from '@/app/Ui/team-chat-center/team-chat-room/team-chat-room.interface';

@Injectable({
  providedIn: 'root',
})
export class TeamInteractionRepository implements ITeamInteractionRepository {
  constructor(
    private httpService: HttpService,
    private databaseService: DatabaseService
  ) { }


  sendMessage(
    data: TeamChatMessageDto
  ): Observable<TeamInteraction> {
    const requestURL = `${environment.apiURL}internal-comm`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      data,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<TeamInteraction>;
        return res.results;
      })
    );
  }

  deleteMessage(
    interaction_id: number
  ): Observable<TeamInteraction> {
    const requestURL = `${environment.apiURL}internal-comm/${interaction_id}`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.delete,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<TeamInteraction>;
        return res.results;
      })
    );
  }

  reactToMessage(interaction_id: number, reaction: string): Observable<{ reaction: string, user_id: string | number }> {
    const requestURL = `${environment.apiURL}internal-comm/${interaction_id}/reaction`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      { reaction },
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<{ reaction: string, user_id: string | number }>;
        return res.results;
      })
    );
  }

  // sendFiles(
  //   data: FormData,
  //   progress$: Observer<any>
  // ): Observable<TeamInteraction[]> {
  //   const requestURL = `${environment.apiURL}internal-comm/file-v2`;
  //   const options = this.httpService.createOptions(
  //     HttpRequestMethod.post,
  //     this.httpService.createHeader(),
  //     requestURL,
  //     data,
  //     false,
  //     null,
  //     true
  //   );
  //   return this.httpService.upload(options).pipe(
  //     tap((event: any) => getUploadEventProgress(event, progress$)),
  //     last(),
  //     map((item) => {
  //       progress$.complete();
  //       let response = new SuccessApiResponse<TeamInteraction[]>();
  //       response.results = item;
  //       return response;
  //     }),
  //     pluck('results'),
  //     pluck('body'),
  //     map((item) => {
  //       let res = item as SuccessApiResponse<TeamInteraction[]>;
  //       return res.results;
  //     })
  //   );
  // }

  updateMessageStatus(
    data: { interation_id: number, data: InternalMessageStatusDto }
  ): Observable<TeamInteraction> {
    const requestURL = `${environment.apiURL}internal-comm/${data.interation_id}/status`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.put,
      this.httpService.createHeader(),
      requestURL,
      data.data,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<TeamInteraction>;
        return res.results;
      })
    );
  }

  getRecentMessages(
    params: RecentMessagesQueryParams
  ): Observable<TeamInteraction[]> {
    const requestURL = `${environment.apiURL}internal-comm/recent`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false,
      params
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<TeamInteraction[]>;
        return res.results;
      })
    );
  }

  getInteractions(
    query: TeamChatThreadMessagesQueryDto
  ): Observable<TeamInteraction[]> {
    const { page, page_size, userId } = query
    const requestURL = `${environment.apiURL}internal-comm/thread/${userId}?page=${page}&page_size=${page_size || 25}`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false,
      {}
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<TeamInteraction[]>;
        return res.results;
      })
    );
  }


  getUserMessages(
    user_id: number,
    query: RecentMessagesQuery
  ): Observable<TeamInteraction<string>[]> {
    const requestURL = `${environment.apiURL}internal-comm/thread/${user_id}`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false,
      query
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<TeamInteraction<string>[]>;
        return res.results;
      })
    );
  }

  saveTeamRecentMessages(
    data: RecentInteraction[],
    params: RecentMessagesQueryParams,
  ): void {
    this.databaseService.transaction(
      'rw!',
      this.databaseService.teamRecentInteractions,
      async () => {
        let rt = this.databaseService.teamRecentInteractions
          .where('page')
          .equals(Number(params.page));
        const recentInteractionsByPage = await rt
          .offset(0)
          .limit(params.page * params.page_size)
          .toArray();
        if (recentInteractionsByPage) {
          const recentInteractionsIds = recentInteractionsByPage.map(
            (item) => item.interaction.user_id
          );
          const newRecentInteractionsIds = data.map((item) => item.interaction.user_id);
          const removed = recentInteractionsIds.filter(
            (item) => newRecentInteractionsIds.indexOf(item) == -1
          );
          await this.databaseService.teamRecentInteractions.bulkDelete(removed);
        }
        await this.databaseService.teamRecentInteractions.bulkPut(data).then();
      }
    );
  }

  deleteTeamRecentInteraction(key: string, id: string): void {
    this.databaseService.teamRecentInteractions.where({ [`${key}`]: id }).delete().then();
  }

  updateInteractionStatus(interaction_id: number, status: InteractionStatus): void {
    this.databaseService.teamRecentInteractions.where('interaction.interaction_id').equals(interaction_id)
      .modify((item: RecentInteraction) => item.interaction.status = status).then();
  }

  saveTeamRecentMessage(data: RecentInteraction): void {
    this.databaseService.teamRecentInteractions.put(data).then();
  }

  getLocalTeamRecentMessages(): Observable<RecentInteraction[]> {
    return from(
      liveQuery(() => {
        this.databaseService.teamRecentInteractions.mapToClass(RecentInteraction);
        let rt = this.databaseService.teamRecentInteractions.orderBy('interaction_dt')
          .offset(0)
          .reverse()
          .toArray();
        return rt;
      })
    );
  }

  saveTeamThreadMessages(data: InteractionThread[], { page, userId, roomId, is_room }: { page: number; userId: number; roomId: number, is_room: boolean }): void {
    this.databaseService.transaction(
      'rw!',
      this.databaseService.teamThreadInteractions,
      async () => {
        let localInteractions = await this.databaseService.teamThreadInteractions
          .where('user_id')
          .equals(String(userId))
          .filter((item) => item.page == page)
          .toArray();
        if (localInteractions?.length) {
          const localIds = localInteractions.map((item) => item.interaction_id as unknown as string);
          const newIds = data.map((item) => item.interaction_id as unknown as string);
          const removed = localIds.filter((item) => newIds.indexOf(item) == -1);
          await this.databaseService.teamThreadInteractions.bulkDelete(removed);
        }
        this.databaseService.teamThreadInteractions
          .bulkPut(data)
          .then()
          .catch((e) => console.log(e));
      }
    );
  }

  deleteTeamThreadInteractions(payload: Object): void {
    this.databaseService.teamThreadInteractions.where(payload).delete();
  }

  saveTeamThreadMessage(data: InteractionThread): void {
    this.databaseService.teamThreadInteractions.put(data)
  }

  setLocalTeamThreadInteraction(interaction: InteractionThread): void {
    this.databaseService.teamThreadInteractions?.mapToClass(InteractionThread);
    this.databaseService.teamThreadInteractions.put(interaction);
  }

  updateLocalTeamThreadInteraction(data: Partial<InteractionThread>, interactionId: string): void {
    this.databaseService.teamThreadInteractions.update(interactionId, data).then();
  }

  updateLocalRoomRecentInteractions(room: IRoom): void {
    this.databaseService.teamRecentInteractions
      .where({ room_id: room.room_id })
      .modify(interaction => {
        interaction.given_name = room.room_name!;
        interaction.avatar = room.avatar!;
        interaction.private = !!room.private
      });
  }

  getLocalTeamMessages({ page, userId }: TeamChatThreadMessagesQueryDto): Observable<InteractionThread[]> {
    return from(
      liveQuery(() => {
        const pages = Array.from({ length: page }, (_, i) => i + 1)
        this.databaseService.teamThreadInteractions.mapToClass(InteractionThread);
        let rt = this.databaseService.teamThreadInteractions
          .where({ user_id: `${userId}` })
          .and((item) => pages.indexOf(item.page) > -1);
        return rt.reverse().sortBy('interaction_dt');
      })
    );
  }

  getLocalTeamMessage(interactionId: string): Observable<InteractionThread | undefined> {
    return from(
      liveQuery(() => {
        this.databaseService.teamThreadInteractions.mapToClass(InteractionThread);
        let query = this.databaseService.teamThreadInteractions
          .where('interaction_id')
          .equals(interactionId)
        return query.first();
      })
    );
  }

  // getLocalTeamRecentMessages(): Observable<RecentInteraction[]> {
  //   return from(
  //     liveQuery(() => {
  //       this.databaseService.teamRecentInteractions.mapToClass(RecentInteraction);
  //       let rt = this.databaseService.teamRecentInteractions.orderBy('interaction_dt')
  //         .offset(0)
  //         .reverse()
  //         .toArray();
  //       return rt;
  //     })
  //   );
  // }

  getYobiOneTeamMessages(): Observable<RecentInteraction | undefined> {
    return from(
      liveQuery(() => {
        this.databaseService.teamThreadInteractions.mapToClass(RecentInteraction);
        let query = this.databaseService.teamRecentInteractions
          .filter(interaction =>
            interaction.family_name === 'Assistant' &&
            interaction.given_name === 'Yobi'
          );
        return query.first();
      })
    )
  }

  fetchTenantRooms() {
    const requestURL = `${environment.apiURL}internal-comm/rooms`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false,
      // { room_name: roomName ?? undefined }
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<IRoom[]>;
        return res.results;
      })
    );
  }

  createRoom(data: InputCreateRoom) {
    const requestURL = `${environment.apiURL}internal-comm/rooms`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      data,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<ICreateRoomResponse>;
        return res.results;
      })
    );
  }

  updateRoom(room_id: string, data: InputCreateRoom) {
    const requestURL = `${environment.apiURL}internal-comm/rooms/${room_id}`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.put,
      this.httpService.createHeader(),
      requestURL,
      data,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<ICreateRoomResponse>;
        return res.results;
      })
    );
  }

  updateRoomImage(room_id: string, data: FormData, progress$: Observer<{ avatar: string }>) {
    const requestURL = `${environment.apiURL}internal-comm/rooms/update_avatar/${room_id}`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      data,
      false,
      null,
      true
    );
    return this.httpService.upload(options).pipe(
      tap((event: any) => getUploadEventProgress(event, progress$)),
      last(),
      map((item) => {
        let response = new SuccessApiResponse<SuccessResponse>();
        response.results = item;
        return response;
      }),
      pluck('results'),
      pluck('body'),
      map((item) => {
        let res = item as SuccessApiResponse<{ avatar: string }>;
        return res.results;
      })
    );
  }

  addRoomPartcipant(room_id: string, participants: (string | number)[]) {
    const requestURL = `${environment.apiURL}internal-comm/rooms/${room_id}/participant`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.put,
      this.httpService.createHeader(),
      requestURL,
      { participants_ids: participants },
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<IParticipantResponse>;
        return res.results;
      })
    );
  }

  fetchRoomParticipant(room_id: string): Observable<IRoomResponse> {
    const requestURL = `${environment.apiURL}internal-comm/rooms/${room_id}/participants`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<IRoomResponse>;
        return res.results;
      })
    );
  }

  removeRoomParticipant(room_id: string, participant_id: string) {
    const requestURL = `${environment.apiURL}internal-comm/rooms/${room_id}/participant/${participant_id}`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.delete,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<IParticipantResponse>;
        return res.results;
      })
    );
  }

  muteRoomNotification(room_id: string, status: 'ON' | 'OFF') {
    const requestURL = `${environment.apiURL}internal-comm/rooms/${room_id}/notifications`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.put,
      this.httpService.createHeader(),
      requestURL,
      status,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<SuccessResponse>;
        return res.results;
      })
    );
  }

  fetchRoomMessages(query: TeamChatThreadMessagesQueryDto) {
    const { page, page_size, roomId } = query
    const requestURL = `${environment.apiURL}internal-comm/rooms/${roomId}/messages?page=${page}&page_size=${page_size || 25}`
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<TeamInteraction[]>;
        return res.results;
      })
    );
  }

  fetchRoomNotifications(room_id: string) {
    const requestURL = `${environment.apiURL}internal-comm/rooms/${room_id}/notifications`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<SuccessResponse>;
        return res.results;
      })
    );
  }

  fetchRoomParticipantsNotifications(room_id: string) {
    const requestURL = `${environment.apiURL}internal-comm/rooms/${room_id}/status`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<SuccessResponse>;
        return res.results;
      })
    );
  }

  fetchRoomThreadMessages(query: TeamChatThreadMessagesQueryDto) {
    const { page, page_size, roomId } = query
    const requestURL = `${environment.apiURL}internal-comm/thread/room/${roomId}?page=${page}&page_size=${page_size || 25}`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<TeamInteraction[]>;
        return res.results;
      })
    );
  }

  fetchParticipantRooms() {
    const requestURL = `${environment.apiURL}internal-comm/rooms/participant`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<IRoom[]>;
        return res.results;
      })
    );
  }

  getInteractionTranscription(interaction_id: number): Observable<InteractionTranscription> {
    const requestURL = `${environment.apiURL}transcriptions/internal_comm/interaction/${interaction_id}`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<InteractionTranscription>;
        return res.results;
      })
    );
  }

}
