import { PhoneState } from '@/app/Data/twilio/voice-client.service';
import {
  MakeVoiceRequestCallAction, SetIncomingCallInformationAction, SetMute, VoiceClientClearCallSessionAction, VoiceDeviceStateChangeAction
} from '@/app/State/VoiceClient/voice-client.action';
import { createReducer, on } from '@ngrx/store';
import {
  ConferenceCustomerParticipant, ConferenceParticipant, ConferenceUpdateStatus
} from '../../core/Models/ConferenceParticipant';
import {
  VoiceClientConferenceCustomerUpdateAction,
  VoiceClientConferenceHostUpdateAction,
  VoiceClientConferenceInfoUpdateAction,
  VoiceClientConferenceMemberUpdateAction,
  VoiceClientConferenceRemoveHostAction,
  VoiceClientConferenceRemoveMemberAction, VoiceClientRequestCallTransferSuccessAction,
  VoiceClientUpdateActiveCallerAction
} from './voice-client-transfer.action';
import {
  VoiceClientActiveCallClearStateAction,
  VoiceClientUpdateIncomingtransferCallFromUserAction
} from './voice-client.action';
export interface DeviceState {
  state: PhoneState; // STANDBY | RINGING | LIVE
}

export enum ConferenceParicipantTypes {
  host = 'host',
  member = 'member',
  customer = 'customer',
}
export interface CallSessionState {
  outgoing?: boolean;
  live?: boolean;
  mute?: boolean;
  from?: string;
  isTransfer?: boolean;
  parentCallSid?: string;
  isTransfering?: boolean;
  channelValue?: string;
  conference?: ConferenceState;
}
export interface ConferenceState {
  particpantsNumber?: number;
  merged?: boolean;
  date?: BigInt;
  participants: ConferenceParticipant[];
}
export interface VoiceClientState {
  device: DeviceState;
  // callSessions: {
  //   outgoing?: boolean;
  //   live?: boolean;
  //   mute?: boolean;
  //   from?: string;
  //   isTransfer?: boolean;
  //   parentCallSid?: string;
  //   isTransfering?: boolean;
  //   channelValue?: string;
  //   conference?: ConferenceState;
  // }[]
  callSession?: CallSessionState
}

export const initCallState: VoiceClientState = {
  device: {
    state: PhoneState.STANDBY,
  },
  // callSessions: []
};

export const callReducer = createReducer(initCallState,
  on(VoiceDeviceStateChangeAction, (state: VoiceClientState, { payload }) => {
    return {
      ...state,
      callSession: payload.state === PhoneState.LIVE ? { ...state.callSession, live: true } : state.callSession,
      device: { ...state.device, state: payload.state }
    }
    // const callSession = state.callSessions.find((callSession) => callSession.parentCallSid === payload.parentCallSid)
    // return {
    //   ...state,
    //   callSessions: payload.state === PhoneState.LIVE
    //     ? [
    //       ...state.callSessions.filter(s => s.parentCallSid !== payload.parentCallSid),
    //       {
    //         ...callSession,
    //         live: true
    //       }
    //     ]
    //     : state.callSessions,
    //   device: {
    //     ...state?.device,
    //     state: payload.state,
    //   },
    // };
  }),

  on(MakeVoiceRequestCallAction, (state: VoiceClientState, { payload }) => {
    return {
      ...state,
      callSession: {
        channelValue: payload.entity.channelValue,
        outgoing: true,
        conference: {
          participants: [
            {
              type: ConferenceParicipantTypes.customer,
              id: ConferenceParicipantTypes.customer,
              number: payload?.entity?.channelValue!,
              displayName: payload.entity.displayName || payload?.entity?.channelValue!,
              avatar: payload.entity.avatar,
              activeCaller: true,
            }
          ]
        },
      }
    }
    // return {
    //   ...state,
    //   callSessions: [
    //     ...state.callSessions,
    //     {
    //       channelValue: payload.entity.channelValue,
    //       outgoing: true,
    //       conference: {
    //         participants: [
    //           {
    //             type: ConferenceParicipantTypes.customer,
    //             id: ConferenceParicipantTypes.customer,
    //             number: payload?.entity?.channelValue!,
    //             displayName: payload.entity.displayName || payload?.entity?.channelValue!,
    //             avatar: payload.entity.avatar,
    //             activeCaller: true,
    //           }
    //         ]
    //       },
    //     }
    //   ].sort((a, b) => ((a as any).parentCallSid ?? '').localeCompare(((b as any).parentCallSid ?? '')))
    // };
  }),

  on(SetIncomingCallInformationAction, (state: VoiceClientState, { payload }) => {
    const { channelValue, parentCallSid, isTransfer, from, displayName, avatar } = payload;
    const participant: ConferenceParticipant = {
      avatar,
      displayName,
      activeCaller: true,
      id: isTransfer ? ConferenceParicipantTypes.host : ConferenceParicipantTypes.customer,
      type: isTransfer ? ConferenceParicipantTypes.host : ConferenceParicipantTypes.customer,
      number: isTransfer ? undefined : channelValue,
      user_id: isTransfer ? channelValue : undefined,
    }
    console.log('clg SetIncomingCallInformationAction', participant, state);
    return {
      ...state,
      callSession: {
        ...state.callSession,
        from,
        isTransfer,
        parentCallSid,
        channelValue,
        conference: {
          ...state.callSession?.conference,
          participants: [
            ...state.callSession?.conference?.participants.filter(p => p.id !== participant.id).map(p => ({ ...p, activeCaller: false })) || [],
            participant
          ].sort((a, b) => a.type.localeCompare(b.type))
        },
      }
    };
    // const callSession = state.callSessions.find((callSession) => callSession.parentCallSid === parentCallSid)
    // return {
    //   ...state,
    //   callSessions: [
    //     ...state.callSessions.filter(s => s.parentCallSid !== parentCallSid),
    //     {
    //       ...callSession,
    //       from,
    //       isTransfer,
    //       parentCallSid,
    //       channelValue,
    //       conference: {
    //         ...callSession?.conference,
    //         participants: [
    //           ...callSession?.conference?.participants.filter(p => p.id !== participant.id).map(p => ({ ...p, activeCaller: false })) || [],
    //           participant
    //         ].sort((a, b) => a.type.localeCompare(b.type))
    //       },
    //     }
    //   ].sort((a, b) => (a.parentCallSid ?? '').localeCompare((b.parentCallSid ?? '')))
    // };
  }),

  on(SetMute, (state: VoiceClientState, { payload: { mute, parentCallSid } }) => {
    return {
      ...state,
      callSession: {
        ...state.callSession,
        mute
      }
    };
    // const callSession = state.callSessions.find((callSession) => callSession.parentCallSid === parentCallSid)
    // return !callSession ? state : {
    //   ...state,
    //   callSessions: [
    //     ...state.callSessions.filter(s => s.parentCallSid !== parentCallSid),
    //     {
    //       ...state.callSessions.find(s => s.parentCallSid === parentCallSid),
    //       mute
    //     }
    //   ].sort((a, b) => (a.parentCallSid ?? '').localeCompare((b.parentCallSid ?? '')))
    // };
  }),

  on(VoiceClientConferenceCustomerUpdateAction,
    (state: VoiceClientState, { payload: { customer, parentCallSid } }) => {
      const conferenceCustomer = state.callSession?.conference?.participants?.find(p => p.type === ConferenceParicipantTypes.customer) as ConferenceCustomerParticipant;
      return {
        ...state,
        callSession: {
          ...state.callSession,
          conference: {
            ...state.callSession?.conference,
            participants: [
              ...state.callSession?.conference?.participants?.filter(p => p.type !== ConferenceParicipantTypes.customer) ?? [],
              {
                ...conferenceCustomer,
                ...customer,
              }
            ].sort((a, b) => a.type.localeCompare(b.type))
          }
        }
      };
      // const callSession = state.callSessions.find((s) => s.parentCallSid === parentCallSid)
      // const conferenceCustomer = callSession?.conference?.participants?.find(p => p.type === ConferenceParicipantTypes.customer) as ConferenceCustomerParticipant;
      // return !callSession ? state : {
      //   ...state,
      //   callSessions: [
      //     ...state.callSessions.filter(s => s.parentCallSid !== parentCallSid),
      //     {
      //       ...callSession,
      //       conference: {
      //         ...state.callSessions.find(s => s.parentCallSid === parentCallSid)?.conference,
      //         participants: [
      //           ...state.callSessions.find(s => s.parentCallSid === parentCallSid)?.conference?.participants?.filter(p => p.type !== ConferenceParicipantTypes.customer) ?? [],
      //           {
      //             ...conferenceCustomer,
      //             ...customer,
      //           }
      //         ].sort((a, b) => a.type.localeCompare(b.type))
      //       }
      //     }
      //   ].sort((a, b) => (a.parentCallSid ?? '').localeCompare((b.parentCallSid ?? '')))
      // };
    }
  ),

  on(VoiceClientConferenceHostUpdateAction,
    (state: VoiceClientState, { payload: { host, parentCallSid } }) => {
      const conferenceHost = state.callSession?.conference?.participants?.find(p => p.type === ConferenceParicipantTypes.host);
      return {
        ...state,
        callSession: {
          ...state.callSession,
          conference: {
            ...state.callSession?.conference,
            participants: [
              ...state.callSession?.conference?.participants?.filter(p => p.type !== ConferenceParicipantTypes.host) ?? [],
              {
                ...conferenceHost,
                ...host
              }
            ].sort((a, b) => a.type.localeCompare(b.type))
          }
        }
      };
      // const callSession = state.callSessions.find((s) => s.parentCallSid === parentCallSid);
      // const conferenceHost = callSession?.conference?.participants?.find(p => p.type === ConferenceParicipantTypes.host);
      // return !callSession ? state : {
      //   ...state,
      //   callSessions: [
      //     ...state.callSessions.filter(s => s.parentCallSid !== parentCallSid),
      //     {
      //       ...callSession,
      //       conference: {
      //         ...callSession.conference,
      //         participants: [
      //           ...callSession.conference?.participants?.filter(p => p.type !== ConferenceParicipantTypes.host) ?? [],
      //           {
      //             ...conferenceHost,
      //             ...host
      //           }
      //         ].sort((a, b) => a.type.localeCompare(b.type))
      //       }
      //     }
      //   ].sort((a, b) => (a.parentCallSid ?? '').localeCompare((b.parentCallSid ?? '')))
      // };
    }
  ),

  on(VoiceClientConferenceMemberUpdateAction,
    (state: VoiceClientState, { payload: { member, parentCallSid } }) => {
      const conferenceMember = state.callSession?.conference?.participants?.find(p => p.user_id === member.user_id);
      return {
        ...state,
        callSession: {
          ...state.callSession,
          conference: {
            ...state.callSession?.conference,
            participants: [
              ...state.callSession?.conference?.participants?.filter(p => p.user_id !== member.user_id) ?? [],
              {
                ...conferenceMember,
                ...member
              }
            ].sort((a, b) => a.type.localeCompare(b.type))
          }
        }
      };
      // const callSession = state.callSessions.find((s) => s.parentCallSid === parentCallSid);
      // const conferenceMember = callSession?.conference?.participants?.find(p => p.user_id === member.user_id);
      // return {
      //   ...state,
      //   callSessions: [
      //     ...state.callSessions.filter(s => s.parentCallSid !== parentCallSid),
      //     {
      //       ...callSession,
      //       conference: {
      //         ...callSession?.conference,
      //         participants: [
      //           ...callSession?.conference?.participants?.filter(p => p.user_id !== member.user_id) ?? [],
      //           {
      //             ...conferenceMember,
      //             ...member
      //           }
      //         ].sort((a, b) => a.type.localeCompare(b.type))
      //       }
      //     }
      //   ].sort((a, b) => (a.parentCallSid ?? '').localeCompare((b.parentCallSid ?? '')))
      // };
    }
  ),

  on(VoiceClientConferenceRemoveHostAction,
    (state: VoiceClientState, { payload: { parentCallSid } }) => {
      const callSession = state.callSession;
      return !callSession?.isTransfer ? state : {
        ...state,
        callSession: {
          ...callSession,
          conference: {
            ...callSession.conference,
            participants: [
              ...callSession.conference?.participants?.filter(p => p.type !== ConferenceParicipantTypes.host) ?? [],
            ].sort((a, b) => a.type.localeCompare(b.type))
          }
        }
      };
      // const callSession = state.callSessions.find((s) => s.parentCallSid === parentCallSid);
      // return !callSession?.isTransfer ? state : {
      //   ...state,
      //   callSessions: [
      //     ...state.callSessions.filter(s => s.parentCallSid !== parentCallSid),
      //     {
      //       ...callSession,
      //       conference: {
      //         ...callSession.conference,
      //         participants: [
      //           ...callSession.conference?.participants?.filter(p => p.type !== ConferenceParicipantTypes.host) ?? [],
      //         ].sort((a, b) => a.type.localeCompare(b.type))
      //       }
      //     }
      //   ].sort((a, b) => (a.parentCallSid ?? '').localeCompare((b.parentCallSid ?? '')))
      // };
    }
  ),

  on(VoiceClientConferenceRemoveMemberAction,
    (state: VoiceClientState, { payload: { member, parentCallSid } }) => {
      const callSession = state.callSession;
      return !callSession ? state : {
        ...state,
        callSession: {
          ...callSession,
          conference: {
            ...callSession?.conference,
            participants: [
              ...callSession?.conference?.participants?.filter(p => p.user_id !== member.user_id).map(p => ({ ...p, activeCaller: p.type === ConferenceParicipantTypes.customer })
              ) ?? [],
            ].sort((a, b) => a.type.localeCompare(b.type))
          }
        }
      };
      // const callSession = state.callSessions.find((s) => s.parentCallSid === parentCallSid);
      // return !callSession ? state : {
      //   ...state,
      //   callSessions: [
      //     ...state.callSessions.filter(s => s.parentCallSid !== parentCallSid),
      //     {
      //       ...callSession,
      //       conference: {
      //         ...callSession?.conference,
      //         participants: [
      //           ...callSession?.conference?.participants?.filter(p => p.user_id !== member.user_id).map(p => ({ ...p, activeCaller: p.type === ConferenceParicipantTypes.customer })
      //           ) ?? [],
      //         ].sort((a, b) => a.type.localeCompare(b.type))
      //       }
      //     }
      //   ].sort((a, b) => (a.parentCallSid ?? '').localeCompare((b.parentCallSid ?? '')))
      // };
    }
  ),

  on(VoiceClientRequestCallTransferSuccessAction,
    (state: VoiceClientState, { payload: { parentCallSid, member } }) => {
      const callSession = state.callSession;
      return !callSession ? state : {
        ...state,
        isTransfering: true,
        callSession: {
          ...callSession,
          conference: {
            ...callSession?.conference,
            participants: [
              ...(callSession.conference?.participants ?? [])
                .filter(p => p.user_id !== member.user_id)
                .map(p => ({ ...p, activeCaller: false })),
              {
                ...member,
                status: ConferenceUpdateStatus.ringing,
                type: ConferenceParicipantTypes.member,
                activeCaller: true
              }
            ].sort((a, b) => a.type.localeCompare(b.type))
          }
        }
      };
    }
    //   const callSession = state.callSessions.find((s) => s.parentCallSid === parentCallSid);
    //   return !callSession ? state : {
    //     ...state,
    //     isTransfering: true,
    //     callSessions: [
    //       ...state.callSessions.filter(s => s.parentCallSid !== parentCallSid),
    //       {
    //         ...callSession,
    //         conference: {
    //           ...callSession?.conference,
    //           participants: [
    //             ...(callSession.conference?.participants ?? [])
    //               .filter(p => p.user_id !== member.user_id)
    //               .map(p => ({ ...p, activeCaller: false })),
    //             {
    //               ...member,
    //               status: ConferenceUpdateStatus.ringing,
    //               type: ConferenceParicipantTypes.member,
    //               activeCaller: true
    //             }
    //           ].sort((a, b) => a.type.localeCompare(b.type))
    //         }
    //       }
    //     ].sort((a, b) => (a.parentCallSid ?? '').localeCompare((b.parentCallSid ?? '')))
    //   };
    // }
  ),

  on(VoiceClientUpdateIncomingtransferCallFromUserAction,
    (state: VoiceClientState, { payload: { user: { user_id, avatar, family_name, given_name }, parentCallSid } }) => {
      const callSession = state.callSession;
      const conference = callSession?.conference;
      const member = conference?.participants.find(p => p.user_id === user_id.toString());
      console.log('clg paytload VoiceClientUpdateIncomingtransferCallFromUserAction', state, callSession, conference, member);
      return !member ? state : {
        ...state,
        callSession: {
          ...callSession,
          conference: {
            ...conference,
            participants: [
              ...conference!.participants.filter(p => p.user_id !== member.user_id),
              {
                ...member,
                avatar,
                displayName: `${given_name} ${family_name}`,
              }
            ].sort((a, b) => a.type.localeCompare(b.type))
          }
        }
      };
      // const callSession = state.callSessions.find(s => s.parentCallSid === parentCallSid);
      // const conference = callSession?.conference;
      // const member = conference?.participants.find(p => p.user_id === id.toString());
      // return !member ? state : {
      //   ...state,
      //   callSessions: [
      //     ...state.callSessions.filter(s => s.parentCallSid !== parentCallSid),
      //     {
      //       ...callSession,
      //       conference: {
      //         ...conference,
      //         participants: [
      //           ...conference!.participants.filter(p => p.user_id !== member.user_id),
      //           {
      //             ...member,
      //             avatar,
      //             displayName: `${given_name} ${family_name}`,
      //           }
      //         ].sort((a, b) => a.type.localeCompare(b.type))
      //       }
      //     }
      //   ].sort((a, b) => (a.parentCallSid ?? '').localeCompare((b.parentCallSid ?? '')))
      // };
    }
  ),

  on(VoiceClientConferenceInfoUpdateAction,
    (state: VoiceClientState, { payload: { conference, parentCallSid } }) => {
      const callSession = state.callSession;
      const stateConference = callSession?.conference;
      return {
        ...state,
        callSession: {
          ...callSession,
          parentCallSid,
          conference: {
            ...stateConference,
            date: conference.date,
            merged: conference.merged,
            particpantsNumber: conference.particpantsNumber,
            participants: stateConference?.participants ?? [],
          }
        }
      };
      // const callSession = state.callSessions.find(s => s.parentCallSid === parentCallSid);
      // const stateConference = callSession?.conference;
      // return {
      //   ...state,
      //   callSessions: [
      //     ...state.callSessions.filter(s => s.parentCallSid !== parentCallSid),
      //     {
      //       ...callSession,
      //       parentCallSid,
      //       conference: {
      //         ...stateConference,
      //         date: conference.date,
      //         merged: conference.merged,
      //         particpantsNumber: conference.particpantsNumber,
      //         participants: stateConference?.participants ?? [],
      //       }
      //     }
      //   ].sort((a, b) => (a.parentCallSid ?? '').localeCompare((b.parentCallSid ?? '')))
      // };
    }
  ),

  on(VoiceClientUpdateActiveCallerAction,
    (state: VoiceClientState, { payload: { id, parentCallSid } }) => {
      const callSession = state.callSession;
      const conference = callSession?.conference;
      return !conference ? state : {
        ...state,
        callSession: {
          ...callSession,
          conference: {
            ...conference,
            participants: [
              ...conference!.participants.map(p => ({ ...p, activeCaller: p.id === id })),
            ].sort((a, b) => a.type.localeCompare(b.type))
          }
        }
      };
      // const callSession = state.callSessions.find(s => s.parentCallSid === parentCallSid);
      // const conference = callSession?.conference;
      // return !conference ? state : {
      //   ...state,
      //   callSessions: [
      //     ...state.callSessions.filter(s => s.parentCallSid !== parentCallSid),
      //     {
      //       ...callSession,
      //       conference: {
      //         ...conference,
      //         participants: [
      //           ...conference!.participants.map(p => ({ ...p, activeCaller: p.id === id })),
      //         ].sort((a, b) => a.type.localeCompare(b.type))
      //       }
      //     }
      //   ].sort((a, b) => (a.parentCallSid ?? '').localeCompare((b.parentCallSid ?? '')))
      // };
    }
  ),
  on(VoiceClientClearCallSessionAction, (state: VoiceClientState, { payload: { parentCallSid } }) => {
    return {
      ...state,
      callSession: undefined
      // callSessions: state.callSessions.filter(s => s.parentCallSid !== parentCallSid)
    };
  }),

  on(VoiceClientActiveCallClearStateAction, (state: VoiceClientState) => {
    return {
      ...initCallState,
    };
  }),

);
