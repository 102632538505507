import { ITaskRepository } from '@/app/core/IRepositories/ITaskepository';
import { Task, TaskComment, TaskDetail } from '@/app/core/Models/Task';
import { SuccessResponse } from '@/app/Data/DTO/successResponse';
import { PatchTaskDto, TaskDto } from '@/app/Data/DTO/TaskDto';
import { TaskStatusDto } from '@/app/Data/DTO/TaskStatusDto';
import { DatabaseService } from '@/app/Data/services/Database/database.service';
import { SuccessApiResponse } from '@/app/Data/services/Networking/ApiResponse';
import { HttpRequestMethod } from '@/app/Data/services/Networking/HttpRequestMethod';
import { HttpService } from '@/app/Data/services/Networking/HttpService';
import { environment } from '@/environments/environment';
import { Injectable } from '@angular/core';
import { liveQuery } from 'dexie';
import { firstValueFrom, from, map, Observable, pluck } from 'rxjs';
import { TaskPriorityDto } from '../DTO/TaskPriorityDto';
import { DtoToFormData } from "@/app/Utilities/helpers";

@Injectable()
export class TaskRepository extends ITaskRepository {
  constructor(
    private httpService: HttpService,
    private databaseService: DatabaseService
  ) {
    super();
  }

  updateTaskStatus(request: TaskStatusDto): Observable<Task> {
    const requestURL = `${environment.apiURL}tasks/${request.taskId}/status`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.put,
      this.httpService.createHeader(),
      requestURL,
      { ...request, log_enabled: true },
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<Task>;
        return res.results;
      })
    );
  }

  updateTaskPriority(request: TaskPriorityDto): Observable<Task> {
    const requestURL = `${environment.apiURL}tasks_v3/priority`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.put,
      this.httpService.createHeader(),
      requestURL,
      { ...request, log_enabled: true },
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<Task>;
        return res.results;
      })
    );
  }

  getTaskById(taskId: number): Observable<TaskDetail> {
    // const requestURL = `${environment.apiURL}tasks/${taskId}/v2`;
    const requestURL = `${environment.apiURL}tasks/${taskId}/v3`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<TaskDetail>;
        return res.results;
      })
    );
  }

  saveTaskDetail(request: TaskDetail): void {
    this.databaseService.transaction(
      'rw!',
      this.databaseService.tasks,
      async () => {
        const task = await this.databaseService.tasks.get(request.task_id as unknown as string);
        const savedTask = Object.assign(new TaskDetail(), { ...task, ...request });
        this.databaseService.tasks.put(savedTask);
      }
    );
  }

  getLocalTaskById(taskId: number): Observable<TaskDetail | undefined> {
    return from(
      liveQuery(() => {
        this.databaseService.tasks.mapToClass(TaskDetail);
        return this.databaseService.tasks.get(taskId as unknown as string);
      })
    );
  }

  addComment(
    comment: { comment: string },
    taskId: number
  ): Observable<TaskComment> {
    const requestURL = `${environment.apiURL}tasks/${taskId}/comment_v2`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      comment,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<TaskComment>;
        return res.results;
      })
    );
  }

  saveLocalTaskComment(comment: TaskComment, tempCommentId?: number): void {
    this.databaseService.transaction(
      'rw!',
      this.databaseService.tasks,
      async () => {
        this.databaseService.tasks.mapToClass(TaskDetail);
        const task = await this.databaseService.tasks.get(comment.task_id as unknown as string);
        if (!task) return;
        const taskComments: TaskComment[] = [
          ...task.comments.filter((c): c is TaskComment => ![comment.comment_id, tempCommentId].includes((c as TaskComment).comment_id)),
          comment
        ]
        task.comments = taskComments.sort((a, b) => new Date(b.created_dt).getTime() - new Date(a.created_dt).getTime())
        this.databaseService.tasks.put(task);
      }
    );
  }

  editComment(
    comment: { comment: string },
    commentId: number,
    taskId: number
  ): Observable<TaskComment> {
    const requestURL = `${environment.apiURL}tasks/${taskId}/comment_v2/${commentId}`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.put,
      this.httpService.createHeader(),
      requestURL,
      comment,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<TaskComment>;
        return res.results;
      })
    );
  }

  deleteLocalTaskComment(commentId: number, taskId: number): void {
    this.databaseService.transaction(
      'rw!',
      this.databaseService.tasks,
      async () => {
        this.databaseService.tasks.mapToClass(TaskDetail);
        const task: TaskDetail | undefined = await this.databaseService.tasks.get(taskId as unknown as string);
        if (task) {
          task.comments = task.comments.filter(
            (item: any) => (item as TaskComment).comment_id != commentId
          );
          this.databaseService.tasks.put(task);
        }
      }
    );
  }

  deleteTaskComment(
    commentId: number,
    taskId: number
  ): Observable<SuccessResponse> {
    const requestURL = `${environment.apiURL}tasks/${taskId}/comment/${commentId}`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.delete,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<SuccessResponse>;
        return res.results;
      })
    );
  }

  createTask(request: TaskDto): Observable<TaskDetail> {
    const requestURL = `${environment.apiURL}tasks_v3`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      { ...request, log_enabled: true },
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<TaskDetail>;
        return res.results;
      })
    );
  }

  isUnread(): Observable<SuccessResponse> {
    const requestURL = `${environment.apiURL}tasks/is-unread`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<SuccessResponse>;
        return res.results;
      })
    );
  }

  editTask(request: TaskDto, taskId: number): Observable<TaskDetail> {
    const requestURL = `${environment.apiURL}tasks_v3/${taskId}`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.put,
      this.httpService.createHeader(),
      requestURL,
      { ...request, log_enabled: true },
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<TaskDetail>;
        return res.results;
      })
    );
  }

  patchTask(payload: PatchTaskDto): Observable<TaskDetail> {
    const requestURL = `${environment.apiURL}tasks_v3/${payload.taskId}`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.patch,
      this.httpService.createHeader(),
      requestURL,
      { ...payload.task },
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<TaskDetail>;
        return res.results;
      })
    );
  }

  updateTaskDetailLocal(task: TaskDetail): Promise<TaskDetail> {
    return new Promise((resolve) => {
      this.databaseService.tasks.update(task.task_id as unknown as string, task).then(async (res) => {
        const updatedTask = await firstValueFrom(this.getLocalTaskById(task.task_id));
        resolve(updatedTask as any);
      });
    })
  }

}
