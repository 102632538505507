import { fileTypeFromName } from '@/app/Utilities/helpers';
import { PlatformService } from '@/app/Utilities/platform/platform.service';
import { FileModel, FileType } from '@/app/core/Models/file';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { NgxViewerDirective } from 'ngx-viewer';

declare type Attachment = Pick<FileModel, 'type' | 'url' | 'name' | 'filename'>;
import Viewer from 'viewerjs';

@Component({
  selector: 'app-attachments-viewer',
  templateUrl: './attachments-viewer.component.html',
  styleUrls: ['./attachments-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AttachmentsViewerComponent {

  @Input('attachments') set attachmentsSetter1(attachments: Attachment[]) {
    this.attachments = attachments.map(({ type, url, name, filename }) => ({
      url,
      name,
      filename,
      type: fileTypeFromName(type ?? name),
    }))
  }
  @Input() size = '5rem'
  @Input() canRemove = false;
  @Input() roundedImage = false;
  @Output() remove = new EventEmitter<number>();

  attachments: Attachment[] = [];

  constructor(
    private platformService: PlatformService,
    private cdr: ChangeDetectorRef
  ) { }

  public viewerOptions: NgxViewerDirective['viewerOptions'] = {
    navbar: false,
    toolbar: {
      zoomIn: 4,
      zoomOut: 4,
      oneToOne: 4,
      reset: 4,
      play: {
        show: 1,
        size: 'large',
      },
      rotateLeft: 4,
      rotateRight: 4,
      flipHorizontal: 4,
      flipVertical: 4,
    },
  };

  openViewer(imageUrl: string) {
    if (imageUrl) {
      const imgElement = document.createElement('img');
      imgElement.src = imageUrl;

      const viewer = new Viewer(imgElement, {
        url: () => imageUrl,
        ...this.viewerOptions,
      });
      viewer.show();
    } else {
      console.error("URL Not available for display");
    }
  }

  openFile(url: string) {
    this.platformService.openURL(url, '_blank')
  }

  detect(): void {
    this.cdr.detectChanges();
  }

  detactAttachments(): void {
    this.cdr.detach();
  }
}
