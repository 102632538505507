import { DeleteUserDto } from './../../Data/DTO/DeleteUserDto';
import {
  EditUserDto,
  EditUserPermissionDto,
  EditUserResponseDto,
} from './../../Data/DTO/CreateUserDto';
import { UserReinviteDto } from './../../Data/DTO/UserInviteDto';
import { SuccessResponse } from './../../Data/DTO/successResponse';
import { Observable } from 'rxjs';
export abstract class IUserRepository {
  abstract editUser(request: EditUserDto, userId: number): Observable<EditUserResponseDto>;
  abstract deleteUser(request: DeleteUserDto): Observable<SuccessResponse>;
  abstract reinviteUser(request: UserReinviteDto): Observable<SuccessResponse>;
  abstract deleteLocalUser(id: string): void;
  abstract editUserAIPermission(request: EditUserPermissionDto): Observable<SuccessResponse>
}
