import { LocalizationViewModel } from '@/app/Ui/ViewModels/localizationViewModel';
import { emailRegExp } from '@/app/Utilities/regExp';
import { Roles } from '@/app/core/Models/Roles';
import { User } from '@/app/core/Models/User';
import { Inbox } from '@/app/core/Models/inbox';
import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CountryCode } from 'libphonenumber-js';
import { firstValueFrom, map } from 'rxjs';
import { SubSink } from 'subsink';
import { EditUserDto, EditUserPermissionDto, InviteUserDto } from './../../../Data/DTO/CreateUserDto';
import { UsersViewModel } from './../../ViewModels/usersViewModel';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { TenantViewModel } from '../../ViewModels/tenantViewModel';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
})
export class UserFormComponent implements OnInit, OnDestroy {
  @Output() onCreate = new EventEmitter();
  creatingUser$ = this.usersViewModel.creatingUser$
  roles = Roles
  userForm: FormGroup;
  isUpdate = false;
  countryCode: CountryCode = 'US';
  sub = new SubSink()
  localization = this.localizationViewModel.localization
  inboxes$ = this.usersViewModel.inboxes$.pipe(map(inboxes => inboxes.filter(i => i.is_channel)));

  aiPermissionsOpt = [
    {
      'name': 'Create Synth Agent',
      model: 'create_synth_agent',
      'value': null
    },
    {
      name: 'Test Synth Agent',
      model: 'test_synth_agent',
      value: null
    },
    {
      name: 'Train Synth Agent',
      model: 'train_synth_agent',
      value: null
    },
    {
      name: 'Configure Synth Agent',
      model: 'configure_synth_agent',
      value: null
    }
  ]

  isAdmin$ = this.tenantViewModel.isAdmin$;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<UserFormComponent>,
    private usersViewModel: UsersViewModel,
    private localizationViewModel: LocalizationViewModel,
    private tenantViewModel: TenantViewModel,
    @Inject(MAT_DIALOG_DATA) public data: { user: User },
  ) {
    this.isUpdate = !!data.user
  }

  ngOnInit(): void {
    this.initForm()
    this.sub.sink = this.inboxes$
      .subscribe(inboxes => {
        this.initInboxes(inboxes)
      });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe()
  }

  initForm(): void {
    this.userForm = this.fb.group({
      firstname: this.isUpdate ? [this.data.user!.firstName ?? '', [Validators.required]] : undefined,
      lastname: this.isUpdate ? [this.data.user!.lastName ?? '', [Validators.required]] : undefined,
      email: !this.isUpdate ? ['', [Validators.required, Validators.pattern(emailRegExp)]] : undefined,
      role: [this.data?.user?.roles[0] ?? null, [Validators.required]],
      channels: this.fb.array([])
    });
    this.patchUserPermissions();
  }

  patchUserPermissions() {
    if (this.data.user !== undefined) {
      this.aiPermissionsOpt = this.aiPermissionsOpt.map(permission => {
        this.data.user.user_permissions.forEach(userPermission => {
          if (userPermission.permission_name === permission.model) {
            permission = { ...permission, value: userPermission.permission_value as any }
          }
        })
        return permission;
      });
    }
  }

  initInboxes(inboxes: Inbox[]): void {
    inboxes.forEach(inboxe => {
      this.channels.push(
        new FormGroup({
          channel_name: new FormControl(inboxe.name),
          channel_label: new FormControl(inboxe.channel_name),
          channel_type: new FormControl(inboxe.channel_type),
          channel_id: new FormControl(inboxe.inbox_id),
          access: new FormControl(false),
          send: new FormControl(false),
        })
      );
    });
    if (this.isUpdate) {
      this.data.user!.accessibility.forEach(inbox => {
        this.channels.controls.find(c => c.value.channel_id === inbox.inbox_id)?.patchValue({
          access: inbox.access,
          send: inbox.send,
        })
      })
    }
  }

  get channels(): FormArray {
    return this.userForm.get('channels') as FormArray;
  }

  close(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    this.userForm.markAllAsTouched();
    const values = this.userForm?.value;
    // this.userForm.get('channels')!.setErrors(
    //   (values as (InviteUserDto | EditUserDto)).channels.every(c => !c.access && !c.send)
    //     ? { empty: true }
    //     : null
    // )

    this.userForm.valid &&
      this.isUpdate
      ? this.updateUser(values)
      : this.addNewUser(values);
  }

  addNewUser(request: InviteUserDto): void {
    const aiPermissionsOpt = this.aiPermissionsOpt.map(permission => {
      return { permission_name: permission.model, permission_value: permission.value === null ? false : permission.value };
    })
    this.usersViewModel.createUser({ ...request, ai_workshop_permissions: aiPermissionsOpt });
  }

  updateUser(request: EditUserDto): void {
    this.usersViewModel.updateUser(request, this.data.user.user_id);
    this.edithUserAIPermissions();
  }

  resetForm(): void {
    this.userForm.reset();
  }

  edithUserAIPermissions() {
    this.aiPermissionsOpt.forEach(async (permission, index) => {
      const payload: EditUserPermissionDto = { user_id: this.data.user.user_id, permission_name: permission.model, permission_value: permission.value as any };
      const response = await firstValueFrom(this.usersViewModel.editUserAIPermission(payload));
      if (index === this.aiPermissionsOpt.length - 1) {
        this.usersViewModel.fetchUsersList();
      }
    })
  }

  async handleUserPermission(event: MatCheckboxChange, index: number) {
    this.aiPermissionsOpt = this.aiPermissionsOpt.map((item, i) => {
      if (index === i) item = { ...item, value: event.checked as boolean | any }
      return item;
    });
  }


}
