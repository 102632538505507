import { bufferToWave } from '@/app/Utilities/helpers';
import { SnackbarService } from '@/app/Utilities/snackbar/snackbar.service';
import { Directive, EventEmitter, Input, NgModule, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';

@Directive({
  selector: '[appSpeachToText]',
  host: {
    '(click)': 'handleClick()',
    '(keyup.enter)': 'handleClick()',
  },
})
export class SpeachToTextDirective implements OnInit {
  notSupported = false
  @Input() canvas: HTMLCanvasElement
  @Output() onStart = new EventEmitter();
  @Output() onResult = new EventEmitter<string>();
  @Output() onEnd = new EventEmitter<{
    file: File | undefined
    content: string
  }>();
  recordedChunks: BlobPart[] = [];
  content = '';
  stream: MediaStream
  recording: boolean;
  // @ts-ignore
  recognition: SpeechRecognition
  mediaRecorder: MediaRecorder | undefined
  onEnd$ = new Subject<void>()
  private audioContext = new AudioContext({
    sampleRate: 8000, // low sample rate but it still does the job and keeps the file size small
  });
  analyser: AnalyserNode;
  freqs: Uint8Array
  ctx: CanvasRenderingContext2D

  constructor(
    private snackBarService: SnackbarService,
  ) { }

  ngOnInit(): void {
    if (['SpeechRecognition', 'webkitSpeechRecognition'].some(x => x in window)) {
      // @ts-ignore
      this.recognition = new (window.SpeechRecognition || (window as any).webkitSpeechRecognition)();
      this.recognition.lang = 'en-US';
      this.recognition.continuous = true;
    } else {
      this.notSupported = true
    }
  }

  handleClick() {
    if (!this.notSupported) {
      this.recording ? this.stop() : this.start()
    } else {
      this.onFailure('SpeechRecognition is not supported in this browser');
    }
  }

  async start() {
    if (navigator.mediaDevices) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
        this.stream = stream;
        this.mediaRecorder = new MediaRecorder(stream);
        this.mediaRecorder.ondataavailable = (({ data }: BlobEvent) => this.recordedChunks.push(data))

        if (this.canvas) {
          this.analyser = this.audioContext.createAnalyser();
          this.analyser.fftSize = 256;
          this.audioContext.createMediaStreamSource(stream).connect(this.analyser);
          this.freqs = new Uint8Array(this.analyser.frequencyBinCount);
          this.ctx = this.canvas.getContext('2d')!
        }

      } catch (err: any) {
        this.onFailure(err?.name === 'NotAllowedError' ? 'User denied the Permission to record!' : 'No Mic Connected');
      }
    }
    if (!this.recognition || !this.mediaRecorder) return;
    if (this.audioContext.state === 'suspended') { await this.audioContext.resume() }

    this.content = ''
    this.recordedChunks = []
    this.recognition.onresult = this.onRecognitionResult.bind(this);
    this.recognition.onerror = this.onRecognitionError.bind(this);
    this.recognition.onend = this.onRecognitionEnd.bind(this);

    this.recognition.start();
    this.mediaRecorder.start()
    this.onStart.emit()
    this.recording = true;
    this.animate()
  }

  stop() {
    this.recording = false;
    this.recognition?.stop();
    this.mediaRecorder?.stop();
    this.mediaRecorder = undefined
    this.stream.getAudioTracks().forEach(t => t.stop())
  }

  animate() {
    this.analyser.getByteFrequencyData(this.freqs);
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.ctx.fillStyle = '#00CCFF'; // Color of the bars
    const bars = Math.floor(this.canvas.width / 2);
    for (var i = 0; i < bars; i++) {
      const bar_x = i * 3;
      const bar_width = 2;
      const bar_height = - (this.freqs[i] / 2);
      this.ctx.fillRect(bar_x, this.canvas.height, bar_width, bar_height)
    }
    if (this.recording) {
      requestAnimationFrame(this.animate.bind(this));
    } else {
      return
    }
  }


  // @ts-ignore
  onRecognitionResult(event: SpeechRecognitionEvent) {
    const transcript: string = event.results[event.results.length - 1][0].transcript;
    this.content = `${this.content} ${transcript}`
    this.onResult.emit(transcript)
  }

  onRecognitionEnd = async () => {
    this.recording && this.stop()
    let file: File | undefined = undefined
    if (this.content) {
      const audioData = await new Blob(this.recordedChunks).arrayBuffer();
      const audioBuffer = await this.audioContext.decodeAudioData(audioData);
      const wavBlob = bufferToWave(audioBuffer, audioBuffer.length);
      file = new File([wavBlob], 'memo.wav', { type: 'audio/wav' });
    }

    this.onEnd.emit({ file, content: this.content })
    this.onEnd$.next()
  }

  // @ts-ignore
  onRecognitionError = (event: SpeechRecognitionErrorEvent) => {
    console.error('Speech recognition error:', event.error);
    this.recording = false;
    this.onFailure('No speech detected')
    this.stop()
  }

  onFailure = (message: string) => {
    this.snackBarService.openAlert({ message, type: 'failure' });
  }
}

@NgModule({
  declarations: [SpeachToTextDirective],
  exports: [SpeachToTextDirective],
  imports: [],
  providers: [],
})
export class SpeachToTextDirectiveModule { }